import { z } from 'zod';
import { PersonCommentCreateSchema } from '../dto';
import { Comment, CommentCreateSchema } from '../../comment';
import { Endpoint, Method } from '../../../infrastructure';

export const PersonCommentCreateRequestSchema = CommentCreateSchema.merge(
  PersonCommentCreateSchema.pick({
    person_id: true,
    metadata: true,
    type: true,
  }),
);
export type PersonCommentCreateRequest = z.infer<typeof PersonCommentCreateRequestSchema>;

export type PersonCommentCreateResponse = Comment;

export type PersonCommentCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/person/:id/comments`,
  PersonCommentCreateResponse,
  undefined,
  undefined,
  PersonCommentCreateRequest
>;
