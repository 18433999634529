import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const PrjOppMergeRequestSchema = z.object({
  merger_id: z.string(),
  receiver_id: z.string(),
});

export type PrjOppMergeRequest = z.infer<typeof PrjOppMergeRequestSchema>;

export type PrjOppMergeResponse = void;

export type PrjOppMergeEndpoint = Endpoint<
  Method.POST,
  '/api/v2/sql-leads/prj-opp-merge',
  PrjOppMergeResponse,
  undefined,
  undefined,
  PrjOppMergeRequest
>;
