import { z } from 'zod';
import { TimeOffRequestCategory } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestCategoryGetByIdParamsSchema = z.object({
  category_id: z.coerce.number(),
});

export type TimeOffRequestCategoryGetByIdParams = z.infer<
  typeof TimeOffRequestCategoryGetByIdParamsSchema
>;

export type TimeOffRequestCategoryGetByIdResponse = TimeOffRequestCategory | null;

export type TimeOffRequestCategoryGetByIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-requests/category/${TimeOffRequestCategoryGetByIdParams['category_id']}`,
  TimeOffRequestCategoryGetByIdResponse,
  TimeOffRequestCategoryGetByIdParams,
  undefined,
  undefined
>;
