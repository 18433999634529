import { z } from 'zod';
import { CommentListItemSchema, CommentUpdateSchema } from '../../comment';
import { Endpoint, Method } from '../../../infrastructure';
import { PersonCommentUpdateSchema } from '../dto';

export const PersonCommentUpdateParamsSchema = z.object({
  person_id: z.string(),
  comment_id: z.string(),
});

export type PersonCommentUpdateParams = z.infer<typeof PersonCommentUpdateParamsSchema>;

export const PersonCommentUpdateRequestSchema =
  CommentUpdateSchema.merge(PersonCommentUpdateSchema);

export type PersonCommentUpdateRequest = z.infer<typeof PersonCommentUpdateRequestSchema>;

export type PersonCommentUpdateResponse = z.infer<typeof CommentListItemSchema>;

export type PersonCommentUpdateEndpoint = Endpoint<
  Method.PATCH,
  `/api/v2/person-comments/${PersonCommentUpdateParams['person_id']}/${PersonCommentUpdateParams['comment_id']}`,
  PersonCommentUpdateResponse,
  PersonCommentUpdateParams,
  undefined,
  PersonCommentUpdateRequest
>;
