import APIRequest from '@src/shared/request';
import { message as Message } from 'antd';
import { ActionRequestEndpoints } from '@ct-internal/api';
import { MutationOptions, MutationParams, MutationResult } from '@src/shared/react-query';
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { MY_NOTIFICATIONS_KEY } from './keys';

// Queries
export const useNotifications = ({
  query,
  options,
}: {
  query?: ActionRequestEndpoints['listMyRequests']['query'];
  options?: UseQueryOptions<ActionRequestEndpoints['listMyRequests']['response']>;
}): UseQueryResult<ActionRequestEndpoints['listMyRequests']['response']> => {
  return useQuery({
    queryKey: [MY_NOTIFICATIONS_KEY],
    queryFn: () => {
      return APIRequest('/action-requests/me', {
        method: 'GET',
        qs: query,
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      });
    },
    ...options,
  });
};

// Mutations
export const useCreateNotification = (
  options?: MutationOptions<ActionRequestEndpoints['create']>,
): MutationResult<ActionRequestEndpoints['create']> => {
  return useMutation({
    ...options,
    mutationFn: ({ request }: MutationParams<ActionRequestEndpoints['create']>) => {
      return APIRequest(`/action-requests`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateNotification = (
  options?: MutationOptions<ActionRequestEndpoints['patch']>,
): MutationResult<ActionRequestEndpoints['patch']> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ params, request }: MutationParams<ActionRequestEndpoints['patch']>) => {
      return APIRequest(`/action-requests/${params?.action_request_id}`, {
        method: 'PATCH',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries([MY_NOTIFICATIONS_KEY]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useBulkCloseNotification = (
  options?: MutationOptions<ActionRequestEndpoints['bulkActionRequestClose']>,
): MutationResult<ActionRequestEndpoints['bulkActionRequestClose']> => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ request }: MutationParams<ActionRequestEndpoints['bulkActionRequestClose']>) => {
      return APIRequest(`/action-requests/bulk-close`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Action Requests closed successfully`);
      queryClient.refetchQueries([MY_NOTIFICATIONS_KEY]);
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error closing Action Requests: ${e.message ?? e}`);
    },
  });
};
