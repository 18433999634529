import { z, ZodObject, ZodUnion } from 'zod';

export enum FilterOperations {
  'EQUAL' = 'EQUAL',
  'NOT_EQUAL' = 'NOT_EQUAL',
  'GREATER_THAN' = 'GREATER_THAN',
  'GREATER_THAN_OR_EQUAL' = 'GREATER_THAN_OR_EQUAL',
  'LESS_THAN' = 'LESS_THAN',
  'LESS_THAN_OR_EQUAL' = 'LESS_THAN_OR_EQUAL',
  'CONTAIN' = 'CONTAIN',
  'NOT_CONTAIN' = 'NOT_CONTAIN',
  'IN' = 'IN',
  'NOT_IN' = 'NOT_IN',
  'STARTS_WITH' = 'STARTS_WITH',
  'ENDS_WITH' = 'ENDS_WITH',
  'ARRAY_OVERLAP' = 'ARRAY_OVERLAP',
}
export const FilterOperationSchema = z.nativeEnum(FilterOperations);

export const FiltersQuerySchema = z.record(z.string(), z.record(FilterOperationSchema, z.any()));

export const isObjectFilter = <T extends FilterSchema>(
  filterSchema: T,
): filterSchema is T extends ZodObject<any> ? T : never =>
  !!(filterSchema as Record<string, any>).shape;

export const isCompositeFilter = <T extends FilterSchema>(
  filterSchema: T,
): filterSchema is T extends ZodUnion<any> ? T : never => !isObjectFilter(filterSchema);

export type FilterSchema =
  | ZodObject<any>
  | ZodUnion<[ZodObject<any>, ZodObject<any>]>
  | ZodUnion<[ZodObject<any>, ZodObject<any>, ZodObject<any>]>;
export type FiltersQuery = z.infer<typeof FiltersQuerySchema>;
export type InferFilterQuery<T extends FilterSchema> = z.infer<T>;

export type GenericFilters<T> = {
  [K in keyof T]: Partial<{
    [key in FilterOperations]: T[K];
  }>;
};

export function getFiltersValues<T>(filters: GenericFilters<T>): { [key in keyof T]: T[key] } {
  const values: { [key in keyof T]: T[key] } = {} as any;

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      values[key] = Object.values(filters[key])[0];
    }
  }

  return values;
}

export function getFilterValue<T>(filter?: Partial<Record<FilterOperations, T>>): T | undefined {
  return filter ? (Object.values(filter)[0] as T) : undefined;
}
