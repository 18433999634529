import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { SFAccountSchema } from './base';

export const CreateAccountRequestSchema = SFAccountSchema.pick({
  sf_account_id: true,
  sf_account_name: true,
  deal_size: true,
  exp_opp_amount: true,
  exp_signed_amount: true,
  next_step_description: true,
  stage: true,
}).partial({
  deal_size: true,
  exp_opp_amount: true,
  exp_signed_amount: true,
  next_step_description: true,
  stage: true,
});
export type CreateAccountRequest = z.infer<typeof CreateAccountRequestSchema>;

export const CreateAccountResponseSchema = z.object({
  brains_client_id: z.string(),
});
export type CreateAccountResponse = z.infer<typeof CreateAccountResponseSchema>;

export type CreateAccountEndpoint = Endpoint<
  Method.POST,
  '/api/sf/clients',
  CreateAccountResponse,
  undefined,
  undefined,
  CreateAccountRequest
>;
