import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { PsnRctr, PsnRctrSchema } from '../base';

export const PsnRctrFindFiltersSchema = PsnRctrSchema.partial();

export const PsnRctrFindQuerySchema = z
  .object({
    filters: PsnRctrFindFiltersSchema.partial(),
  })
  .partial();
export type PsnRctrFindQuery = z.infer<typeof PsnRctrFindQuerySchema>;

export type PsnRctrFindResponse = PsnRctr[];

export type PsnRctrFindEndpoint = Endpoint<
  Method.GET,
  `/api/v2/psn-rctr`,
  PsnRctrFindResponse,
  undefined,
  PsnRctrFindQuery,
  undefined
>;
