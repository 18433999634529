import { z } from 'zod';
import { ContractTypes } from '../../contract-templates';
import { Endpoint, Method } from '../../../infrastructure';

export const ApplicationSetExpectedContracTypeRequestSchema = z.object({
  contractType: z.enum(ContractTypes).nullish(),
  contractTemplateId: z.number().nullish(),
});

export type ApplicationSetExpectedContracTypeRequest = z.infer<
  typeof ApplicationSetExpectedContracTypeRequestSchema
>;

export const ApplicationSetExpectedContracTypeParamsSchema = z.object({
  application_id: z.string(),
});
export type ApplicationSetExpectedContracTypeParams = z.infer<
  typeof ApplicationSetExpectedContracTypeParamsSchema
>;

export type ApplicationSetExpectedContractTypeResponse = any;

export type ApplicationSetExpectedContractTypeEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/applications/${ApplicationSetExpectedContracTypeParams['application_id']}/set-expected-contract-type`,
  ApplicationSetExpectedContractTypeResponse,
  ApplicationSetExpectedContracTypeParams,
  undefined,
  ApplicationSetExpectedContracTypeRequest
>;
