import { RevenueRateSchema } from '../base';
import { z } from 'zod';

export const RevenueRateUpdateSchema = RevenueRateSchema.pick({
  revenuerate_id: true,
  revenueamount: true,
  dtfrom: true,
  dtto: true,
}).partial({
  dtfrom: true,
  dtto: true,
  revenueamount: true,
});

export type RevenueRateUpdate = z.infer<typeof RevenueRateUpdateSchema>;
