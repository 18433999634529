import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

const signerEventTypes = [
  'contract_viewed',
  'disable_reminders',
  'email_contract_sent',
  'email_delivery_failed',
  'email_final_contract_sent',
  'email_spam_complaint',
  'mobile_update_request',
  'reminder_emailed',
  'sign_contract',
  'signature_declined',
  'sms_contract_sent',
  'sms_delivery_failed',
  'sms_final_contract_sent',
] as const;

const signerEventTypesEnum = z.enum(signerEventTypes);

export const signerEventSchema = z.object({
  event: signerEventTypesEnum,
  timestamp: z.string(),
});

const contractSignerSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    mobile: z.string(),
    company_name: z.string(),
    sign_page_url: z.string(),
    events: signerEventSchema.array(),
    signer_field_values: z.record(z.string()),
  }),
);

export const sendContractPreviewResponseSchema = z.object({
  id: z.string(),
  status: z.union([z.literal('sent'), z.literal('signed'), z.literal('withdrawn')]),
  title: z.string(),
  metadata: z.string(),
  source: z.union([z.literal('api'), z.literal('ui'), z.literal('zapier')]),
  test: z.union([z.literal('yes'), z.literal('no')]),
  contract_pdf_url: z.string().optional(),
  signers: contractSignerSchema,
});
export type SendContractPreviewResponse = z.infer<typeof sendContractPreviewResponseSchema>;

export type SendContractPreviewEndpoint = Endpoint<
  Method.POST,
  `/api/v2/contract-templates/:templateId/send-preview`,
  SendContractPreviewResponse,
  { templateId: number },
  {},
  {}
>;
