import { z } from 'zod';
import { LeadSchema, ProjectMongoSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';
import { dateString } from '../../infrastructure/schema';

export const LeadDetailSchema = LeadSchema.pick({
  project_id: true,
  client_id: true,
  dtplannedstart: true,
  dtplannedend: true,
  duration: true,
  hard_date: true,
  hire_group: true,
  name: true,
  timezone: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_monthly_margin: true,
  expected_team_size: true,
  islead: true,
  owner_id: true,
  project_sf_id: true,
  sf_stage: true,
  sf_description: true,
  sf_description_updated_at: true,
  sf_notes_updated_at: true,
  is_plan_included: true,
  sf_expected_monthly_revenue: true,
  dtupdate: true,
  dtcreate: true,
  duration_weeks: true,
  sf_locked_logs: true,
  is_sf_locked: true,
  is_pre_opportunity: true,
  is_starter_project: true,
  sf_next_update_on: true,
}).extend({
  client_name: z.string(),
  client_short_name: z.string(),
  client_as_of_ymonth: z.number().nullish(),
  positions_revenue: z.number().nullable(),
  positions_cost: z.number().nullable(),
  positions_size: z.number().nullable(),
  positions_duration: z.number().nullable(),
  positions_start: dateString.nullable(),
  positions_end: dateString.nullable(),
  dt_archived: dateString.nullable(),
  positions_max_revenueamount: z.number().nullable(),
  positions_min_revenueamount: z.number().nullable(),
  positions_hire_group: z.array(z.string()).nullable(),
  probability: z.number().nullish(),
  owner_name: z.string(),
  has_assignment: z.boolean(),
  mongoLead: ProjectMongoSchema,
  dtsigned: z.string().nullable(),
});

export type LeadDetail = z.infer<typeof LeadDetailSchema>;

export const LeadDetailParamsSchema = z.object({
  project_id: z.string(),
});
export type LeadDetailParams = z.infer<typeof LeadDetailParamsSchema>;

export type LeadDetailResponse = LeadDetail;

export type LeadDetailEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-leads/:${LeadDetailParams['project_id']}`,
  LeadDetailResponse,
  LeadDetailParams,
  undefined,
  undefined
>;
