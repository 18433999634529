import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TCoachAiCheckinSchema } from '../schemas';

export const MakeCheckinRequestSchema = z.object({
  date: z.string().datetime().optional(),
  email: z.string(),
  status: TCoachAiCheckinSchema.shape.status,
  summary: z.string().optional(),
});
export type MakeCheckinRequest = z.infer<typeof MakeCheckinRequestSchema>;

export type MakeCheckinResponse = {
  checkin_id: number;
};

export type MakeCheckinEndpoint = Endpoint<
  Method.POST,
  '/api/coach-ai/checkin',
  MakeCheckinResponse,
  {},
  {},
  MakeCheckinRequest
>;
