import { z } from 'zod';
import { ClientSchema } from './base';

export const ClientUpdateSchema = ClientSchema.pick({
  client_id: true,
  name: true,
  ext_billing_client_id: true,
  is_pre_client: true,
  is_forecast_client: true,
  isactive: true,
  isdev: true,
  short_name: true,
  client_sf_id: true,
  client_as_of_ymonth: true,
  industry: true,
  size: true,
  country_id: true,
  state_or_province: true,
  stage: true,
  deal_size: true,
  exp_opp_amount: true,
  exp_signed_amount: true,
  next_step_description: true,
}).partial({
  client_sf_id: true,
  ext_billing_client_id: true,
  client_as_of_ymonth: true,
  is_pre_client: true,
  is_forecast_client: true,
  isactive: true,
  isdev: true,
  name: true,
  short_name: true,
  industry: true,
  size: true,
  country_id: true,
  state_or_province: true,
  stage: true,
  deal_size: true,
  exp_opp_amount: true,
  exp_signed_amount: true,
  next_step_description: true,
});

export type ClientUpdate = z.infer<typeof ClientUpdateSchema>;
