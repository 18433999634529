import { z } from 'zod';
import { PersonCommentSchema } from '../base';

export const PersonCommentCreateSchema = PersonCommentSchema.pick({
  mongo_id: true,
  type: true,
  person_id: true,
  comment_id: true,
  metadata: true,
});
export type PersonCommentCreate = z.infer<typeof PersonCommentCreateSchema>;
