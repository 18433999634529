import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const PlanReportCsvItemSchema = z.object({
  sort: z.number(),
  group: z.number(),
  section: z.string(),

  ymonth: z.number(),
  client: z.string().nullable(),
  project: z.string().nullable(),
  level: z.string().nullable(),
  amount: z.number(),
});

export type PlanReportCsvItem = z.infer<typeof PlanReportCsvItemSchema>;

export const PlanReportCsvFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});

export type PlanReportCsvFilters = z.infer<typeof PlanReportCsvFiltersSchema>;

export const PlanReportCsvQuerySchema = z.object({
  filters: PlanReportCsvFiltersSchema.optional(),
});
export type PlanReportCsvQuery = z.infer<typeof PlanReportCsvQuerySchema>;

export type PlanReportCsvResponse = PlanReportCsvItem[];

export type PlanReportCsvEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/plan-report.csv',
  PlanReportCsvResponse,
  undefined,
  PlanReportCsvQuery,
  undefined
>;
