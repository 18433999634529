/* eslint-disable max-lines */
import request from '@shared/request';
import qs from 'query-string';

//USER
export function updateUserYears({ userId, applicationId, years }) {
  return request(`/users/${userId}/years`, {
    method: 'PUT',
    body: {
      years: years || '',
      applicationId,
    },
  });
}

export function getApplicationVoiceNote({ applicationId }) {
  return request(`/applications/${applicationId}/get-voice-note-url`, {
    method: 'GET',
  });
}

export function updateUserEnglishLevel({ userId, applicationId, englishLevel }) {
  return request(`/users/${userId}/english-level`, {
    method: 'PUT',
    body: {
      englishLevel,
      applicationId,
    },
  });
}

export function updateApplicationTags({ applicationId, tags }) {
  return request(`/applications/${applicationId}/tags`, {
    method: 'PUT',
    body: {
      tags,
    },
  });
}

export function getApplicationTags({ applicationId, tags }) {
  return request(`/applications/tags`, {
    method: 'GET',
  });
}

export function updateUserLinkedinURL({ userId, applicationId, linkedinURL }) {
  return request(`/users/${userId}/linkedin-url`, {
    method: 'PUT',
    body: {
      linkedinURL,
      applicationId,
    },
  });
}

export function updateUserSalary({ userId, applicationId, salary }) {
  return request(`/users/${userId}/salary`, {
    method: 'PUT',
    body: {
      salary: salary || '',
      applicationId,
    },
  });
}

export function updateUserSalaryCurrency({ userId, applicationId, currency }) {
  return request(`/users/${userId}/salaryCurrency`, {
    method: 'PUT',
    body: {
      currency: currency || '',
      applicationId,
    },
  });
}

export function updateUserPhone({ userId, applicationId, phone }) {
  return request(`/users/${userId}/phone`, {
    method: 'PUT',
    body: {
      phone: phone || '',
      applicationId,
    },
  });
}

export function updateUserAvailability({ userId, applicationId, availability }) {
  return request(`/applications/${applicationId}/availability`, {
    method: 'PUT',
    body: {
      availability: availability,
      applicationId,
      userId,
    },
  });
}

// APPLICATION

export function get({ applicationId, onlyOwnComments }) {
  const query = qs.stringify({
    onlyOwnComments,
  });

  return request(`/applications/${applicationId}?${query}`, {
    method: 'GET',
  });
}

export function setReviewed({ applicationId }) {
  return request(`/applications/${applicationId}/reviewed`, {
    asRaw: true,
    method: 'POST',
  });
}
export function updateApplicationSummary({ applicationId, summary }) {
  return request(`/applications/${applicationId}/summary`, {
    method: 'PUT',
    body: {
      id: applicationId,
      summary: summary,
    },
  });
}

export function updateApplicationVisaRequired({ applicationId, visaStatus, visaDetails }) {
  return request(`/applications/${applicationId}/set-visa-info`, {
    method: 'PUT',
    body: {
      is_visa_required: visaStatus === 1,
      visa_status: visaStatus,
      visa_details: visaDetails,
    },
  });
}

/**
 *
 * @param {{applicationId: string, reviewed: boolean}} payload
 * @returns {Promise<{message: string, application: Record<string, any>}>}
 */
export function updateApplicationMgmtReviewed({ applicationId, reviewed }) {
  return request(`/applications/${applicationId}/mgmt-reviewed`, {
    method: 'PUT',
    body: {
      reviewed,
    },
  });
}

export function getPast({ applicationId }) {
  return request(`/applications/${applicationId}/past-applications`, {
    method: 'GET',
  });
}

//APPLICATION VIDEO ANSWERS
export function getApplicationVideoAnswers({ applicationId }) {
  return request(`/answers?applicationId=${applicationId}`, {
    method: 'GET',
  });
}

export function deleteApplicationVideoAnswer({ answerId }) {
  return request(`/answers/${answerId}`, {
    method: 'DELETE',
  });
}

export function getAssignmentOptions({ source }) {
  let url = '/applications/assignment-options?show=current,deleted,special';
  if (source) {
    url = `${url}&source=${source.name}`;
  }
  return request(url, {
    method: 'GET',
  });
}
//UPDATE_ASSIGNMENT
export function updateUserAssignments({ applicationId, assignments }) {
  return request(`/applications/${applicationId}/assignments`, {
    method: 'PUT',
    body: {
      assignments: assignments && assignments.length ? assignments : [],
    },
  });
}

//UPDATE_ASSIGNMENT
export function updateUserAssignment({ applicationId, assignment }) {
  return request(`/applications/${applicationId}/assignment`, {
    method: 'PUT',
    body: {
      assignment,
    },
  });
}

export function updateApplicationJob({ applicationId, jobId }) {
  return request(`/applications/${applicationId}/job`, {
    method: 'PUT',
    body: {
      id: applicationId,
      jobId: jobId,
    },
  });
}

export function updateApplicationOwner({ applicationId, ownerId }) {
  return request(`/applications/${applicationId}/owner`, {
    method: 'PUT',
    body: {
      id: applicationId,
      ownerId: ownerId,
    },
  });
}

export function updateApplicationAuthor({ applicationId, authorId }) {
  return request(`/applications/${applicationId}/author`, {
    method: 'PUT',
    body: {
      id: applicationId,
      authorId: authorId,
    },
  });
}

export function updateApplicationTechInterviewer({ applicationId, techInterviewerId }) {
  return request(`/applications/${applicationId}/tech-interviewer`, {
    method: 'PUT',
    body: {
      techInterviewerId,
    },
  });
}

export function updateApplicationContractStatus({ applicationId, contractStatus }) {
  return request(`/applications/${applicationId}/contract-status`, {
    method: 'PUT',
    body: {
      id: applicationId,
      contractStatus: contractStatus,
    },
  });
}

//SKILLS
export function getApplicationSkills({ extras }) {
  let endpoint = '/applications/skills';

  if (extras) {
    endpoint = `${endpoint}?extras=${extras}`;
  }

  return request(endpoint, {
    method: 'GET',
  });
}

export function updateApplicationSkills(body) {
  return request(`/applications/${body.applicationId}/skills`, {
    method: 'PUT',
    body: {
      id: body.applicationId,
      skillLevels: body.skills,
      currentSkill: body.currentSkill,
      stateSkill: body.stateSkill,
    },
  });
}

// STATUSES

export function getStatuses() {
  return request('/applications/statuses', {
    method: 'GET',
  });
}

export function getTechTestStatuses() {
  return request('/applications/tech-test-statuses', {
    method: 'GET',
  });
}

export function updateStatus({ applicationId, status, opts = {} }) {
  return request(`/applications/${applicationId}/status/${status}`, {
    method: 'POST',
    body: {
      ...{ opts: opts },
    },
  });
}

export function updateClock({ applicationId }) {
  return request(`/applications/${applicationId}/clock`, {
    method: 'POST',
  });
}

export function updateReachDate({ applicationId, reachDate }) {
  return request(`/applications/${applicationId}/reach-date`, {
    method: 'PUT',
    body: { reachDate: reachDate },
  });
}

// STATUSES

export function getCalendlyList() {
  return request('/applications/calendly-list', {
    method: 'GET',
  });
}

// TEST LISTS
export function getApplicationTestList() {
  return request('/tech-tests', {
    method: 'GET',
  });
}

export function getBrainsAssignments({ applicationId }) {
  return request(`/applications/${applicationId}/brains-assignments`, {
    method: 'GET',
  });
}

// INTERVIEWS
export function scheduleInterview({ applicationId, interviewerUsername, eventTypeId }) {
  return request(`/applications/${applicationId}/calendly`, {
    method: 'PUT',
    body: {
      username: interviewerUsername,
      eventTypeId,
    },
  });
}

export function scheduleInterviewNotification({ applicationId, directedAt, isUrgent }) {
  return request(`/applications/${applicationId}/notifications`, {
    method: 'POST',
    body: {
      directedAt,
      isUrgent,
    },
  });
}

export function bookCalendlyExtensionInterview({ applicationId, user }) {
  return request(`/applications/${applicationId}/book-calendly-extension-interview`, {
    method: 'PUT',
    body: {
      calendlyUser: user,
    },
  });
}

export function sendTest({ applicationId, testId, testName }) {
  return request(`/tech-tests/${testId}/request`, {
    method: 'POST',
    body: {
      applicationId,
      testName,
    },
  });
}

// INTERVIEW OPTIONS

export function getApplicationInterviewOptions() {
  return request('/applications/interview-options', {
    method: 'GET',
  });
}

// LIST OF INTERVIEWS PER CANDIDATE

export function getApplicationInterviewList(applicationId) {
  return request(`/applications/${applicationId.applicationId}/interviews`, {
    method: 'GET',
  });
}

export function getInterviewerAvailability({ interviewerId, date }) {
  return request(
    `/applications/interviews/availability?interviewerId=${interviewerId}&date=${date}`,
    {
      method: 'GET',
    },
  );
}

// TIME LINE

export function getApplicationTimelineList(applicationId) {
  return request(`/applications/${applicationId.applicationId}/timeline`, {
    method: 'GET',
  });
}

// CREATE NEW INTERVEW PER APPLICATION
export function createApplicationInterview({ applicationId, data }) {
  return request(`/applications/${applicationId}/interviews`, {
    method: 'POST',
    body: {
      status: data.status,
      interviewer: data.interviewer,
      datetime: data.datetime,
      type: data.type,
      time: data.time,
      date: data.date,
      sendInvite: data.sendInvite,
    },
  });
}

// DELETE INTERVIEW
export function deleteApplicationInterview({ applicationId, interviewId }) {
  return request(`/applications/${applicationId}/interviews/${interviewId}`, {
    method: 'DELETE',
  });
}

// GET INTERVIEW DETAILS
export function getApplicationInterview({ applicationId, interviewId }) {
  return request(`/applications/${applicationId}/interviews/${interviewId}`, {
    method: 'GET',
  });
}

// UPDATE INTERVIEW
export function updateApplicationInterview({ applicationId, data, interviewId }) {
  return request(`/applications/${applicationId}/interviews/${interviewId}`, {
    method: 'PUT',
    body: {
      status: data.status,
      interviewer: data.interviewer,
      datetime: data.datetime,
      type: data.type,
      time: data.time,
      date: data.date,
      sendInvite: data.sendInvite,
    },
  });
}

// CREATE NEW APPLICANT
export function createNewApplicant({ data }) {
  return request('/applications/refer', {
    method: 'POST',
    body: {
      country: data.country,
      city: data.city,
      state: data.state,
      email: data.email,
      job: data.job,
      name: data.name,
      negotiated_salary: data.negotiated_salary,
      referredBy: data.referredBy,
      sourcedVia: data.sourcedVia,
      appliedVia: data.appliedVia,
      foundVia: data.foundVia,
      author: data.author,
      owner: data.owner,
      status: data.status,
      summary: data.summary,
      linkedinURL: data.linkedinURL,
      latitude: data.latitude,
      longitude: data.longitude,
      urls: data.urls,
      availability: data.availability,
      degreeName: data.degreeName,
      degreeYear: data.degreeYear,
      phone: data.cellphone,
      questions: data.questions,
      excitementRate: data.excitementRate,
      seniority: data.seniority,
    },
  });
}

// GET USERS
export function getUserList({ id }) {
  const query = qs.stringify({
    id,
  });

  return request(`/users?${query}`, {
    method: 'GET',
  });
}

export function getAdminList() {
  return request('/users/admins', {
    method: 'GET',
  });
}

export function getActiveRecruitersList() {
  return request('/users/recruiters?onlyActive=true', {
    method: 'GET',
  });
}

export function getRecruitersList() {
  return request('/users/recruiters', {
    method: 'GET',
  });
}

export function getSourcedByList() {
  return request('/users/sourced-by-list', {
    method: 'GET',
  });
}

// GET SOURCE LIST
export function getSourceList({ sourceType } = {}) {
  const query = qs.stringify({
    sourceType,
  });

  return request(`/sources?${query}`, {
    method: 'GET',
  });
}

// GET MEDIUM LIST
export function getMediumList({ mediumType } = {}) {
  const query = qs.stringify({
    mediumType,
  });

  return request(`/mediums?${query}`, {
    method: 'GET',
  });
}

// UPDATE DEVSKILLER RESULT
export function updateDevskillerResult({
  applicationId,
  devskillerTestId,
  score,
  comment,
  isDefault,
}) {
  const path = `/applications/${applicationId}/devskillerResult/${devskillerTestId}`;

  return request(path, {
    method: 'PUT',
    body: {
      score: score,
      comment: comment,
      isDefault,
    },
  });
}

// ARCHIVE DEVSKILLER TEST
export function archiveDevskillerTest({ applicationId, devskillerTestId }) {
  const path = `/applications/${applicationId}/archive/${devskillerTestId}`;

  return request(path, {
    method: 'PUT',
    body: {},
  });
}

// SYNC DEVSKILLER TEST
export function syncTests({ email }) {
  const path = '/applications/sync-tests/';

  return request(path, {
    method: 'POST',
    body: {
      email,
    },
  });
}

// CANCEL ASSESSMENT REQUESTED
export function cancelAssessmentRequest({ applicationId, testId }) {
  return request(`/applications/${applicationId}/cancel-request/${testId}`, {
    method: 'DELETE',
  });
}

// GET FOUND VIA LIST
export function getFoundList() {
  return request('/applications/found-via-options', {
    method: 'GET',
  });
}

// GET APPLIED VIA LIST
export function getAppliedList() {
  return request('/applications/applied-via-options', {
    method: 'GET',
  });
}

/*FINALIZING SECTION */

export function setDoNotContactBeforeDate({ applicationId, doNotContactBeforeDate }) {
  return request(`/applications/${applicationId}/do-not-contact-before-date`, {
    method: 'PUT',
    body: {
      doNotContactBeforeDate: doNotContactBeforeDate || '',
      applicationId,
    },
  });
}

//UPDATE hire date

export function updateUserHireDate({ applicationId, userId, hireDate }) {
  return request(`/users/${userId}/hire-date`, {
    method: 'PUT',
    body: {
      hireDate: hireDate || '',
      applicationId,
    },
  });
}

export function updateUserOfferTerms({
  applicationId,
  userId,
  title,
  email,
  name,
  salary,
  hireDate,
  onboardingDate,
  doublingUntilDate,
  ptoStartDate,
  ptoEndDate,
  ptoIsPaid,
  contractType,
  isBillable,
  initialProject,
  initialProjectId,
  clevertechEmail,
  salaryYearly,
  salaryCurrency,
  department,
  hiredFor,
  noticePeriodContract,
  contractTemplateId,
  additionalContractParams,
}) {
  return request(`/users/${userId}/offer-terms`, {
    method: 'PUT',
    body: {
      applicationId,
      title,
      email,
      name,
      salary,
      hireDate,
      onboardingDate,
      doublingUntilDate,
      ptoStartDate,
      ptoEndDate,
      ptoIsPaid,
      contractType,
      isBillable,
      initialProject,
      initialProjectId,
      clevertechEmail,
      salaryYearly,
      salaryCurrency,
      department,
      hiredFor,
      noticePeriodContract,
      contractTemplateId,
      additionalContractParams,
    },
  });
}

export function updateUserNativeEnglish({ applicationId, userId, nativeEnglish }) {
  return request(`/users/${userId}/native-english`, {
    method: 'PUT',
    body: {
      nativeEnglish,
      applicationId,
    },
  });
}

export function updateUserLeadExperience({ applicationId, userId, leadExperience }) {
  return request(`/users/${userId}/lead-experience`, {
    method: 'PUT',
    body: {
      leadExperience,
      applicationId,
    },
  });
}

export function updateUserSeniority({ applicationId, userId, seniority }) {
  return request(`/users/${userId}/seniority`, {
    method: 'PUT',
    body: {
      seniority,
      applicationId,
    },
  });
}

export function updateUserWorkingStatus({ applicationId, userId, workingStatus }) {
  return request(`/users/${userId}/workingstatus`, {
    method: 'PUT',
    body: {
      workingStatus,
      applicationId,
    },
  });
}

export function updateUserStartDate({
  applicationId,
  userId,
  hireDate,
  salary,
  resendContract,
  title,
  name,
  email,
  clevertechEmail,
  isBillable,
  contractType,
  onboardingDate,
  doublingUntilDate,
  ptoStartDate,
  ptoEndDate,
  ptoIsPaid,
  initialProject,
  salaryYearly,
  salaryCurrency,
  department,
  hiredFor,
  noticePeriodContract,
  contractTemplateId,
  additionalContractParams,
}) {
  return request(`/users/${userId}/start-date`, {
    method: 'PUT',
    body: {
      hireDate,
      salary,
      resendContract,
      applicationId,
      title,
      name,
      email,
      clevertechEmail,
      isBillable,
      contractType,
      onboardingDate,
      doublingUntilDate,
      ptoStartDate,
      ptoEndDate,
      ptoIsPaid,
      initialProject,
      salaryYearly,
      salaryCurrency,
      department,
      hiredFor,
      noticePeriodContract,
      contractTemplateId,
      additionalContractParams,
    },
  });
}

export function initializeUserOnBrains({
  applicationId,
  userId,
  title,
  email,
  clevertechEmail,
  name,
  salary,
  salaryRaw,
  desiredSalary,
  contractType,
  isBillable,
  hireDate,
  onboardingDate,
  doublingUntilDate,
  ptoStartDate,
  ptoEndDate,
  ptoIsPaid,
  initialProject,
  salaryYearly,
  salaryCurrency,
  department,
  hiredFor,
  contractTemplateId,
  additionalContractParams,
}) {
  return request(`/users/${userId}/initialize`, {
    method: 'POST',
    body: {
      applicationId,
      title,
      email,
      clevertechEmail,
      name,
      salary,
      salaryRaw,
      desiredSalary,
      hireDate,
      onboardingDate,
      doublingUntilDate,
      ptoStartDate,
      ptoEndDate,
      ptoIsPaid,
      contractType,
      isBillable,
      initialProject,
      salaryYearly,
      salaryCurrency,
      department,
      hiredFor,
      contractTemplateId,
      additionalContractParams,
    },
  });
}

export function updateUserNeedContract({ applicationId, contractNeeded }) {
  return request(`/applications/${applicationId}/contract-needed/`, {
    method: 'PUT',
    body: {
      contractNeeded: contractNeeded,
    },
  });
}

/**
 *
 * @param {{applicationId: string, autoSigned: boolean}} payload
 * @returns {Promise<{message: string, application: Record<string, any>}>}
 */
export function updateApplicationAutoSignContractCT({ applicationId, autoSign }) {
  return request(`/applications/${applicationId}/auto-sign-contract-ct`, {
    method: 'PUT',
    body: {
      autoSign,
    },
  });
}

export function sendPreContractLetter({ userId }) {
  return request(`/users/${userId}/send-precontract-letter`, {
    method: 'POST',
  });
}
export function sendOnBoardingLetter({ userId }) {
  return request(`/users/${userId}/send-onboarding-letter`, {
    method: 'POST',
  });
}

export function setExcitement({ applicationId, excitement }) {
  return request(`/applications/${applicationId}/excitements`, {
    method: 'POST',
    body: {
      excitement,
    },
  });
}

export function deleteApplication({ applicationId }) {
  return request(`/applications/${applicationId}`, {
    method: 'DELETE',
  });
}

export function reEnableOnboardingForm({ applicationId }) {
  return request(`/applications/${applicationId}/exported`, {
    method: 'PUT',
    body: {
      exportedToBrains: 'false',
    },
  });
}

export function uploadDocument({ applicationId, file }) {
  return request(`/applications/${applicationId}/documents`, {
    method: 'POST',
    body: file,
  });
}

export function deleteDocument({ applicationId, documentUrl }) {
  return request(`/applications/${applicationId}/documents`, {
    method: 'DELETE',
    body: {
      documentUrl,
    },
  });
}

export function downloadDocument({ applicationId, documentUrl }) {
  return request(`/applications/${applicationId}/${documentUrl}`, {
    method: 'GET',
    asRaw: true,
  });
}

export function getScorecardList({ applicationId }) {
  return request(`/applications/${applicationId}/scorecards`, {
    method: 'GET',
  });
}

export function createScorecard({ applicationId, data }) {
  return request(`/applications/${applicationId}/scorecards`, {
    method: 'POST',
    body: { ...data },
  });
}

export function getScorecardDetails({ applicationId, scid }) {
  return request(`/applications/${applicationId}/scorecards/${scid}`, {
    method: 'GET',
  });
}

export function updateScorecard({ applicationId, scid, data }) {
  return request(`/applications/${applicationId}/scorecards/${scid}`, {
    method: 'PUT',
    body: {
      ...data,
    },
  });
}

export function deleteScorecard({ applicationId, scid }) {
  return request(`/applications/${applicationId}/scorecards/${scid}`, {
    method: 'DELETE',
  });
}

export function getDepartmentOptions() {
  return request('/applications/department-options', {
    method: 'GET',
  });
}
