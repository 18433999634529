import { z } from 'zod';
import { PersonCommentSchema } from '../base';

export const PersonCommentUpdateSchema = PersonCommentSchema.pick({
  comment_id: true,
  person_id: true,
  metadata: true,
}).partial({
  metadata: true,
});

export type PersonCommentUpdate = z.infer<typeof PersonCommentUpdateSchema>;
