import { Endpoint, Method } from '../../../infrastructure';
import { z } from 'zod';
import { PsnRctr, PsnRctrSchema } from '../base';

export const PsnRctrGetParamsSchema = PsnRctrSchema.pick({
  person_id: true,
});
export type PsnRctrGetParams = z.infer<typeof PsnRctrGetParamsSchema>;

export type PsnRctrGetResponse = PsnRctr | null;

export type PsnRctrGetEndpoint = Endpoint<
  Method.GET,
  `/api/v2/psn-rctr/${PsnRctrGetParams['person_id']}`,
  PsnRctrGetResponse,
  PsnRctrGetParams,
  undefined,
  undefined
>;
