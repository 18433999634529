import { z } from 'zod';
import { Endpoint, Method, FilterOperations, makeSorter } from '../../../infrastructure';
import { PersonCommentListItem } from '../dto';

export const PersonCommentListParamsSchema = z.object({
  person_id: z.string(),
});

export type PersonCommentListParams = z.infer<typeof PersonCommentListParamsSchema>;

export const PersonCommentListFiltersSchema = z.object({
  person_id: z.record(z.enum([FilterOperations.EQUAL, FilterOperations.IN]), z.string()).optional(),
  system_generated: z
    .record(z.enum([FilterOperations.EQUAL, FilterOperations.NOT_EQUAL]), z.boolean())
    .optional(),
  dt_created: z
    .record(
      z.enum([
        FilterOperations.GREATER_THAN,
        FilterOperations.GREATER_THAN_OR_EQUAL,
        FilterOperations.LESS_THAN,
        FilterOperations.LESS_THAN_OR_EQUAL,
      ]),
      z.string(),
    )
    .optional(),
  dt_deleted: z
    .record(
      z.enum([
        FilterOperations.GREATER_THAN,
        FilterOperations.GREATER_THAN_OR_EQUAL,
        FilterOperations.LESS_THAN,
        FilterOperations.LESS_THAN_OR_EQUAL,
        FilterOperations.EQUAL,
        FilterOperations.NOT_EQUAL,
      ]),
      z.string().nullish(),
    )
    .optional(),
  comment_tags: z
    .record(
      z.enum([FilterOperations.IN, FilterOperations.NOT_IN, 'AUTHOR_ID']),
      z.array(z.string()),
    )
    .optional(),
});

export type PersonCommentListFilters = z.infer<typeof PersonCommentListFiltersSchema>;

export const PersonCommentListSorterSchema = makeSorter(z.enum(['dt_created'])).optional();
export type PersonCommentListSorter = z.infer<typeof PersonCommentListSorterSchema>;

export const PersonCommentListRequestSchema = z.object({
  context: z.enum(['Person', 'Application']).optional().default('Person').optional(),
  filters: PersonCommentListFiltersSchema,
  sorter: PersonCommentListSorterSchema,
  limit: z.number({ coerce: true }).default(5),
  skip: z.number({ coerce: true }).default(0),
});

export type PersonCommentListRequest = z.infer<typeof PersonCommentListRequestSchema>;

export type PersonCommentListResponse = PersonCommentListItem[];

export type PersonCommentListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/person-comments/${PersonCommentListParams['person_id']}`,
  PersonCommentListResponse,
  PersonCommentListParams,
  undefined,
  undefined
>;
