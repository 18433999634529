import React from 'react';
import moment from 'moment';
import { Tooltip, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import New from './new.png';
import './index.less';

interface NewInfoTooltipProps extends React.PropsWithChildren {
  size?: 'small' | 'medium' | 'large';
  showUntil?: string;
}

const NewInfoTooltip = ({ children, size = 'medium', showUntil }: NewInfoTooltipProps) => {
  const iconSizes = { small: 14, medium: 18, large: 22 };

  if (showUntil && moment().isAfter(moment(showUntil))) {
    return null;
  }

  return (
    <Tooltip rootClassName="new-info" placement="bottomRight" title={children}>
      <Space size={4} style={{ height: iconSizes[size] }}>
        <InfoCircleOutlined style={{ fontSize: iconSizes[size] }} className="new-info-icon" />
        <img src={New} alt="New" className="new-info-image" />
      </Space>
    </Tooltip>
  );
};

export default NewInfoTooltip;
