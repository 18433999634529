import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteCreateInSFParamsSchema = z.object({
  comment_id: z.coerce.number(),
});
export type OpportunityNoteCreateInSFParams = z.infer<typeof OpportunityNoteCreateInSFParamsSchema>;

export type OpportunityNoteCreateInSFEndpoint = Endpoint<
  Method.POST,
  `/api/sf/opportunity-notes/in-salesforce/${OpportunityNoteCreateInSFParams['comment_id']}`,
  {},
  OpportunityNoteCreateInSFParams,
  undefined,
  undefined
>;
