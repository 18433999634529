import { z } from 'zod';
import { dateString } from '../../../infrastructure';
import { SFActiveStatus } from '../../../modules/projects';

export const SFOpportunitySchema = z.object({
  brains_client_id: z.string(),
  opportunity_name: z.string(),
  sf_opportunity_id: z.string(),
  brains_owner_id: z.string(),
  end_date: dateString.nullable(),
  start_date: dateString.nullable(),
  revenue: z.number().nullable(),
  stage: z.string(),
  description: z.string().nullish(),
  is_starter_project: z.boolean().nullable(),
  next_update_on: dateString.nullable(),
  active_status: z.nativeEnum(SFActiveStatus).nullable(),
  probability: z.number().nullable(),
  financial_probability: z.number().nullable(),
  exp_dt_close: dateString.nullable(),
  team_size: z.number().nullable(),
});
export type SFOpportunity = z.infer<typeof SFOpportunitySchema>;

export enum SFStage {
  'DS' = 'Discovery',
  'SF' = 'Scope/fit',
  'IS' = 'Initial Scope',
  'PD' = 'Proposal/Contract Development',
  'CR' = 'Contract Review',
  'VA' = 'Verbal Agreement',
  'CW' = 'Closed Won',
  'CL' = 'Closed Lost',
}
