import { Badge, Card, Space, Typography } from 'antd';

import { ActionRequest, ActionRequestComponentKeys } from '@ct-internal/api';

import { ActionComponent, ActionConfig } from './ActionTypes';
import CloseNotification from './ActionTypes/CloseNotification';

interface NotificationCardProps {
  selectedNotifications: ActionRequest[];
  actions?: ActionRequestComponentKeys[];
}

const defaultBulkActions = [
  ActionRequestComponentKeys.DropInterview,
  ActionRequestComponentKeys.NoShowInterview,
  ActionRequestComponentKeys.InterviewConducted,
];

const BulkActions = ({ selectedNotifications, actions }: NotificationCardProps) => {
  const options: (ActionComponent | undefined)[] = [CloseNotification];

  if (actions) {
    options.unshift(...actions.map((key) => ActionConfig[key]).filter((component) => !!component));
  } else {
    options.unshift(
      ...defaultBulkActions.map((key) => ActionConfig[key]).filter((component) => !!component),
    );
  }

  return (
    <div className="notifications-bulk-actions">
      <Card
        className="notification-card"
        actions={options?.map(
          (Component) => Component && <Component bulkNotifications={selectedNotifications} />,
        )}
      >
        <Space>
          <Badge color="blue" showZero count={selectedNotifications.length} />
          <Typography.Text>Selected action requests</Typography.Text>
        </Space>
      </Card>
    </div>
  );
};

export default BulkActions;
