import { z } from 'zod';

export enum PersonCommentType {
  'ACCOMPLISHMENT' = 'ACCOMPLISHMENT',
  'CHECKIN' = 'CHECKIN',
  'COMMENT' = 'COMMENT',
}

export const PersonCommentSchema = z.object({
  person_comment_id: z.number(),
  comment_id: z.number(),
  person_id: z.string().length(24),
  type: z.nativeEnum(PersonCommentType).optional().default(PersonCommentType.COMMENT),
  mongo_id: z.string().length(24).nullish().optional(),
  metadata: z
    .object({
      roles: z.array(z.string()).default([]).optional(),
      hashtags: z.array(z.string()).default([]).optional(),
      person_tags: z.array(z.string()).default([]).optional(),
      attachments: z.array(z.any()).default([]).optional(),
      system_generated: z.boolean().default(false).optional(),
      important: z.boolean().default(false).optional(),
      pinned: z.boolean().default(false).optional(),
      board_id: z.number().optional(),
      removed_comment_tags: z.array(z.string()).default([]).optional(),
    })
    .passthrough()
    .optional(),
});

export type PersonComment = z.infer<typeof PersonCommentSchema>;
