import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ArchiveFOpportunityParamsSchema = z.object({
  brains_project_id: z.string(),
});
export type ArchiveSFOpportunityParams = z.infer<typeof ArchiveFOpportunityParamsSchema>;

export type ArchiveSFOpportunityResponse = void;

export type ArchiveSFOpportunityEndpoint = Endpoint<
  Method.DELETE,
  `/api/sf/opportunities/${ArchiveSFOpportunityParams['brains_project_id']}`,
  ArchiveSFOpportunityResponse,
  ArchiveSFOpportunityParams,
  undefined,
  undefined
>;
