import React from 'react';
import { Row, Col, Spin } from 'antd';

interface QuickSearchV2NoContentPlaceholderProps {
  searchResult: Array<any>;
  isLoading: boolean;
}

const QuickSearchV2NoContentPlaceholder = (props: QuickSearchV2NoContentPlaceholderProps) => {
  const { searchResult, isLoading } = props;
  if (isLoading || !searchResult) {
    return (
      <Row align="middle">
        <Col flex="auto">Searching...</Col>
        <Col>
          <Spin size="small" />
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle">
      <Col flex="auto">No applications found.</Col>
    </Row>
  );
};

export default QuickSearchV2NoContentPlaceholder;
