const SENTRY_URL = 'https://4a265a1670474900bf9081f9d41f9442@sentry.cleverbuild.biz/3';
const GOOGLE_CLIENT_ID = '40323449079-ianvo4sdc6hdjk14bahjlkodcrgmiq8b.apps.googleusercontent.com';
const SMARTLOOK_ID = '49bb245bb8687bbf73d643b841034ac45bbc4d38';
const SMARTLOOK_REGION = 'us';

const url_prefix = process.env.URL_PREFIX
  ? process.env.URL_PREFIX
  : process.env.REACT_URL_PREFIX || '';

const envs = {
  local: {
    ENV: 'local',
    API_BASE: 'https://local.cleverbuild.biz:8043',
    APP_BASE: 'https://local.cleverbuild.biz:5101',
    AUTH_API_BASE: 'https://local.cleverbuild.biz:3400',
    STRAPI_API_BASE: 'http://localhost:1338/api',
    APM_RUM_NAME: 'cleverbrains-q',
    // APM_RUM_URL: 'https://4d7683951df441f4af9959049bb20ffa.apm.us-east-1.aws.cloud.es.io:443',
    // SENTRY_URL,
    GOOGLE_CLIENT_ID,
    GOOGLE_ANALYTICS: 'XXXXXXXX',
    GOOGLE_TAG_MANAGER: '',
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    SALESFORCE_BASE_URL: 'https://lumenalta--dev.sandbox.my.salesforce.com',
  },
  dev: {
    ENV: 'development',
    API_BASE: 'https://brains-api.clevertech.dev',
    APP_BASE: 'https://q-federation.clevertech.dev',
    AUTH_API_BASE: `https://${url_prefix}auth-api.clevertech.dev`,
    STRAPI_API_BASE: `https://${url_prefix}q-cms.clevertech.dev/api`,
    APM_RUM_NAME: 'cleverbrains-q',
    // APM_RUM_URL: 'https://4d7683951df441f4af9959049bb20ffa.apm.us-east-1.aws.cloud.es.io:443',
    // SENTRY_URL,
    GOOGLE_ANALYTICS: 'XXXXXXXX',
    GOOGLE_TAG_MANAGER: '',
    GOOGLE_CLIENT_ID,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    SALESFORCE_BASE_URL: 'https://lumenalta--dev.sandbox.my.salesforce.com',
  },
  prod: {
    ENV: 'production',
    API_BASE: 'https://brains-api.clevertech.biz',
    APP_BASE: 'https://q-federation.clevertech.biz',
    AUTH_API_BASE: 'https://auth-api.clevertech.biz',
    STRAPI_API_BASE: 'https://q-cms.clevertech.biz/api',
    APM_RUM_NAME: 'cleverbrains-q',
    // APM_RUM_URL: 'https://1197fbcaeec14244ac3e54f027ff1bf7.apm.us-east-1.aws.cloud.es.io:443',
    SENTRY_URL,
    GOOGLE_ANALYTICS: 'G-J01QBLD44L',
    GOOGLE_TAG_MANAGER: 'GTM-N5VBW6G',
    GOOGLE_CLIENT_ID,
    SMARTLOOK_ID,
    SMARTLOOK_REGION,
    SALESFORCE_BASE_URL: 'https://lumenalta.lightning.force.com',
  },
};

const localHostnames = ['local.cleverbuild.biz', 'localhost', '127.0.0.1'];

const calculateConfig = () => {
  if (typeof window === 'undefined') {
    return envs['local'];
  }

  const { hostname } = window.location;
  if (localHostnames.indexOf(hostname) >= 0) {
    return envs['local'];
  }

  const hostnameParts = hostname.split('.');
  if (hostnameParts[hostnameParts.length - 1] === 'biz') {
    return envs['prod'];
  }

  const env = hostnameParts[hostnameParts.length - 1] as 'local' | 'dev' | 'prod';
  const config = envs[env];
  return config;
};

export const config = calculateConfig();
