import { useQuery } from '@tanstack/react-query';

const fetchToUSDRates = async () => {
  const response = await fetch(
    `https://openexchangerates.org/api/latest.json?app_id=63039df9288842f2b7f3c80ff62e552d`,
  );
  const data = await response.json();
  return data.rates;
};

export type CurrencyCode = 'USD' | 'CAD';

export const useToUSDRate = ({ currency }: { currency?: CurrencyCode } = {}) => {
  const { data, isFetching } = useQuery(
    ['toUSDRate', currency],
    async () => {
      const rates = await fetchToUSDRates();
      return currency ? rates[currency] : rates;
    },
    {
      enabled: currency ? currency !== 'USD' : true,
      placeholderData: currency === undefined ? undefined : 1,
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  );

  return { toUSDRate: data, isToUSDRateFetching: isFetching };
};
