import { RevenueRateSchema } from '../base';
import { z } from 'zod';

export const RevenueRateCreateSchema = RevenueRateSchema.pick({
  revenueamount: true,
  dtfrom: true,
  dtto: true,
  position_id: true,
  project_id: true,
});
export type RevenueRateCreate = z.infer<typeof RevenueRateCreateSchema>;
