import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICEBulkSetDroppedRequestSchema = z.object({
  interview_calendar_events_id: z.coerce.number().array(),
});
export type ICEBulkSetDroppedRequest = z.infer<typeof ICEBulkSetDroppedRequestSchema>;

export type ICEBulkSetDroppedResponse = InterviewCalendarEvent[];

export type ICEBulkSetDroppedEndpoint = Endpoint<
  Method.POST,
  `/api/v2/interview-calendar-events/bulk-set-dropped`,
  ICEBulkSetDroppedResponse,
  undefined,
  undefined,
  ICEBulkSetDroppedRequest
>;
