import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { stringToBoolean } from '../../../infrastructure/schema';

export const ApplicationQuickSearchQuerySchema = z.object({
  textQuery: z.string().trim(),
  opts: z
    .object({
      es: stringToBoolean.optional(),
      pg: stringToBoolean.optional(),
    })
    .optional(),
});
export type ApplicationQuickSearchQuery = z.infer<typeof ApplicationQuickSearchQuerySchema>;

export type ApplicationQuickSearchItem = {
  applicationId: string;
  name: string;
  jobShortName: string;
  cost?: number;
  salaryValue: number;
  salaryCurrency: string;
  usdSalary?: number;
  contractType: string;
  timezoneShort?: string;
  declaredYoE?: number;
  countryShort?: string;
  statusId?: string;
  statusHuman?: string;
  updatedAt: string;
};

export type ApplicationQuickSearchResponse = ApplicationQuickSearchItem[];

export type ApplicationQuickSearchEndpoint = Endpoint<
  Method.GET,
  '/api/v2/application-search/quick',
  ApplicationQuickSearchResponse,
  undefined,
  ApplicationQuickSearchQuery,
  undefined
>;
