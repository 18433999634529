import moment from 'moment';
import { ActionRequest } from '@ct-internal/api';
import { useBulkCloseNotification, useUpdateNotification } from '@src/queries/Notification';

export const useCloseNotification = ({
  notification,
  bulkNotifications,
}: {
  notification?: ActionRequest;
  bulkNotifications?: ActionRequest[];
}) => {
  const { mutateAsync: updateNotification } = useUpdateNotification();
  const { mutateAsync: bulkCloseNotification } = useBulkCloseNotification();

  return async () => {
    if (notification) {
      await updateNotification({
        params: { action_request_id: notification.action_request_id },
        request: { dt_action_completed: moment().toISOString() },
      });
    }

    if (bulkNotifications) {
      await bulkCloseNotification({
        request: {
          action_requests_id: bulkNotifications.map(
            (notification) => notification.action_request_id,
          ),
        },
      });
    }
  };
};
