import { z } from 'zod';
import { dateString, FilterOperations, makeSorter } from '../../infrastructure';

export const CommentTagSchema = z.object({
  comment_tag_id: z.number(),
  name: z.string(),
  mongo_id: z.string().nullable(),
  description: z.string().nullable(),
  emoji: z.string().nullable(),
  color: z.string().nullable(),
  author_id: z.string(),

  dt_created: dateString,
  dt_updated: dateString,
});

const FILTERABLE_STRING_SCHEMA = z.union([
  z.record(z.enum([FilterOperations.EQUAL, FilterOperations.NOT_EQUAL]), z.string().nullable()),
  z.record(z.enum([FilterOperations.IN, FilterOperations.NOT_IN]), z.string().array()),
]);

const FILTERABLE_CONTAIN_SCHEMA = z.record(
  z.enum([FilterOperations.CONTAIN, FilterOperations.NOT_CONTAIN]),
  z.string().nullable(),
);

export const CommentTagFilterSchema = z.object({
  author_id: FILTERABLE_STRING_SCHEMA.optional(),
  color: FILTERABLE_STRING_SCHEMA.optional(),
  description: FILTERABLE_CONTAIN_SCHEMA.optional(),
  emoji: FILTERABLE_STRING_SCHEMA.optional(),
  name: z.union([FILTERABLE_STRING_SCHEMA, FILTERABLE_CONTAIN_SCHEMA]).optional(),
});

export type CommentTagFilter = z.infer<typeof CommentTagFilterSchema>;

export type CommentTag = z.infer<typeof CommentTagSchema>;

export const CommentTagCountSchema = z.object({
  _id: z.string(),
  value: z.string(),
  label: z.string(),
  count: z.number(),
});

export type CommentTagCount = z.infer<typeof CommentTagCountSchema>;

export const CommentTagSorterSchema = makeSorter(z.enum(['name', 'dt_created', 'dt_updated']));
export type CommentTagSorter = z.infer<typeof CommentTagSorterSchema>;

export const ERROR_COMMENT_TAG_NOT_FOUND = 'Comment tag not found.';
export const ERROR_COMMENT_TAG_DUPLICATED_NAME =
  'Already exists comment tag with this name, duplication not allowed';

export const FINANCIAL_TAG_NAME = 'Financial';
