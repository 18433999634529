import { ClientStage } from '../../../modules/clients';
import { z } from 'zod';
export const SFAccountSchema = z.object({
  sf_account_id: z.string(),
  sf_account_name: z.string(),
  stage: z.nativeEnum(ClientStage).nullable(),
  deal_size: z.number().nullable(),
  exp_opp_amount: z.number().nullable(),
  exp_signed_amount: z.number().nullable(),
  next_step_description: z.string().nullable(),
});
export type SFAccount = z.infer<typeof SFAccountSchema>;
