import { z } from 'zod';
import { ContractTypeSchema, ContractTemplateSchema } from '../schemas';
import { Endpoint, Method } from '../../../infrastructure';

export const GetContractTemplateResponseSchema = ContractTemplateSchema;
export type GetContractTemplateResponse = z.infer<typeof GetContractTemplateResponseSchema>;

export const GetContractTemplateQuerySchema = z.object({
  type: ContractTypeSchema.optional(),
});
export type GetContractTemplateQuery = z.infer<typeof GetContractTemplateQuerySchema>;

export type GetContractTemplateEndpoint = Endpoint<
  Method.GET,
  `/api/v2/contract-templates/:templateId`,
  GetContractTemplateResponse,
  { templateId: number },
  GetContractTemplateQuery,
  {}
>;
