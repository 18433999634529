import { z } from 'zod';
import {
  Endpoint,
  Method,
  makeSorter,
  stringToBoolean,
  dateString,
  ReportResponse,
  ReportsQuerySchema,
  FilterOperations,
} from '../../../infrastructure';
import { CoachAiCheckin, CoachAiCheckinStatuses } from '../schemas';

export const CheckinReportFiltersSchema = z
  .object({
    project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    coach_ids: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    status: z.record(z.enum([FilterOperations.IN]), z.enum(CoachAiCheckinStatuses).array()),
    is_reviewed: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean),
    dt_from: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString),
    dt_to: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), dateString),
    target_group_ids: z.record(z.enum([FilterOperations.ARRAY_OVERLAP]), z.coerce.number().array()),
  })
  .partial();
export type CheckinReportFilters = z.infer<typeof CheckinReportFiltersSchema>;

export const CheckinReportSorterSchema = makeSorter(
  z.enum(['person', 'coach', 'project', 'date', 'status']),
);
export type CheckinReportSorter = z.infer<typeof CheckinReportSorterSchema>;

export const CheckinReportQuerySchema = ReportsQuerySchema.extend({
  filters: CheckinReportFiltersSchema.optional(),
  sorter: CheckinReportSorterSchema.optional(),
});
export type CheckinReportQuery = z.infer<typeof CheckinReportQuerySchema>;

export type CheckinReportResponse = ReportResponse<CoachAiCheckin>;

export type CheckinReportEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/checkin-report',
  CheckinReportResponse,
  {},
  CheckinReportQuery,
  {}
>;
