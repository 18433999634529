import { z } from 'zod';
import { RevenueRateDeleteSchema } from '../crud';
import { Endpoint, Method } from '../../../infrastructure';

export const RevenueRateDeleteParamsSchema = RevenueRateDeleteSchema.pick({
  revenuerate_id: true,
});
export type RevenueRateDeleteParams = z.infer<typeof RevenueRateDeleteParamsSchema>;

export type RevenueRateDeleteResponse = {};

export type RevenueRateDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/revenue-rate/${RevenueRateDeleteParams['revenuerate_id']}`,
  RevenueRateDeleteResponse,
  RevenueRateDeleteParams,
  undefined,
  undefined
>;
