import { z } from 'zod';
import { RevenueRateCreateSchema } from '../crud';
import { RevenueRate } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const RevenueRateCreateRequestSchema = RevenueRateCreateSchema.pick({
  dtfrom: true,
  dtto: true,
  position_id: true,
  project_id: true,
  revenueamount: true,
});
export type RevenueRateCreateRequest = z.infer<typeof RevenueRateCreateRequestSchema>;

export type RevenueRateCreateResponse = RevenueRate;

export type RevenueRateCreateEndpoint = Endpoint<
  Method.POST,
  '/api/v2/revenue-rate/',
  RevenueRateCreateResponse,
  undefined,
  undefined,
  RevenueRateCreateRequest
>;
