import z from 'zod';
import { makeSorter, stringToBoolean, PaginationQuerySchema } from '../../infrastructure';
import { Comment, AuthorExpanded } from '../comment';

export const ProjectCommentContextSchema = z.object({
  context: z.literal('Project'),
  project_id: z.string(),
});
export type ProjectCommentContext = z.infer<typeof ProjectCommentContextSchema>;

export const PersonCommentContextSchema = z.object({
  context: z.literal('Person'),
  person_id: z.string(),
  type: z.enum(['CHECKIN', 'ACCOMPLISHMENT', 'COMMENT']).optional().default('COMMENT'),
  metadata: z
    .object({
      roles: z.array(z.string()).default([]).optional(),
      hashtags: z.array(z.string()).default([]).optional(),
      system_generated: z.boolean().default(false).optional(),
      important: z.boolean().default(false).optional(),
      pinned: z.boolean().default(false).optional(),
    })
    .passthrough()
    .optional(),
});
export type PersonCommentContext = z.infer<typeof PersonCommentContextSchema>;

export const PositionCommentContextSchema = z.object({
  context: z.literal('Position'),
  position_id: z.string(),
});
export type PositionCommentContext = z.infer<typeof PositionCommentContextSchema>;

export const ApplicationCommentContextSchema = z.object({
  context: z.literal('Application'),
  application_id: z.string(),
});
export type ApplicationCommentContext = z.infer<typeof ApplicationCommentContextSchema>;

export const CandidacyCommentContextSchema = z.object({
  context: z.literal('Candidacy'),
  application_id: z.string(),
  position_id: z.string().optional(),
});
export type CandidacyCommentContext = z.infer<typeof CandidacyCommentContextSchema>;

export const CommentContextSchema = z.discriminatedUnion('context', [
  PositionCommentContextSchema,
  ApplicationCommentContextSchema,
  CandidacyCommentContextSchema,
  ProjectCommentContextSchema,
  PersonCommentContextSchema,
]);
export type CommentContext = z.infer<typeof CommentContextSchema>;
export type CommentContextType = CommentContext['context'];

const CommentContextAudienceSchema = z.object({
  comment_audience_id: z.number(),
  title: z.string(),
});

export type CommentContextAudience = z.infer<typeof CommentContextAudienceSchema>;

type BaseContextualComment = Omit<Comment, 'mongo_id' | 'author_id'> & {
  author: AuthorExpanded | null;
  is_important?: boolean;
  is_email: boolean;
  rank?: number;
  audience?: string[];
  tags?: string[];
};

type PositionExpanded = {
  id: string;
  name: string;
  project: string;
  project_id: string;
};

type ProjectExpanded = {
  id: string;
  name: string;
  is_lead: boolean;
  client: string;
  client_id: string;
};

type PersonExpanded = {
  person_comment_id: number;
  person_id: string;
  person_name: string;
  person_email: string;
  gravatar_url: string;
};

export type ApplicationComment = BaseContextualComment & {
  context: ApplicationCommentContext;
  applicant_name: string;
};
export type PositionComment = BaseContextualComment & {
  context: PositionCommentContext;
  position: PositionExpanded;
};
export type CandidacyComment = BaseContextualComment & {
  context: CandidacyCommentContext;
  position: PositionExpanded;
  applicant_name: string;
};
export type ProjectComment = BaseContextualComment & {
  context: ProjectCommentContext;
  project: ProjectExpanded;
};

type PersonComment = BaseContextualComment & {
  context: PersonCommentContext;
  person: PersonExpanded;
};

export type ContextualComment =
  | ApplicationComment
  | PositionComment
  | CandidacyComment
  | ProjectComment
  | PersonComment;

/*
 * Standard Comments
 */

export const CreateContextualCommentRequestSchema = z.object({
  comment: z.string(),
  tags: z.string().array().default([]).optional(),
  context: CommentContextSchema,
  is_important: stringToBoolean.optional(),
  send_to_slack: z.boolean().optional(),
  audience: z.string().array().optional(),
});
export type CreateContextualCommentRequest = z.infer<typeof CreateContextualCommentRequestSchema>;

export const PatchContextualCommentRequestSchema = z.object({
  comment: z.string().optional(),
  tags: z.string().array().optional().default([]),
  context: CommentContextSchema.optional(),
  is_important: stringToBoolean.optional(),
  send_to_slack: z.boolean().optional(),
  audience: z.string().array().optional(),
});
export type PatchContextualCommentRequest = z.infer<typeof PatchContextualCommentRequestSchema>;

export const ListContextualCommentSorterSchema = makeSorter(z.enum(['rank', 'dt_created']));
export type ListContextualCommentSorter = z.infer<typeof ListContextualCommentSorterSchema>;

export const ListContextualCommentQuerySchema = z.object({
  contexts: CommentContextSchema.array().optional(),
  is_important: stringToBoolean.optional(),
  include_emails: stringToBoolean.optional(),
  audience: z.coerce.string().array().optional(),
  sorter: ListContextualCommentSorterSchema.optional(),
  pagination: PaginationQuerySchema.optional(),
});
export type ListContextualCommentQuery = z.infer<typeof ListContextualCommentQuerySchema>;
export type ListContextualCommentResponse = {
  comments: ContextualComment[];
  count: number;
  limit: number;
  skip: number;
};

/*
 * Comments with rank (AKA Attributes)
 */

export const ListRankedContextualCommentQuerySchema = z.object({
  contexts: CommentContextSchema.array(),
});
export type ListRankedContextualCommentQuery = z.infer<
  typeof ListRankedContextualCommentQuerySchema
>;
export type ListRankedContextualCommentResponse = {
  comments: ContextualComment[];
  count: number;
  limit: number;
  skip: number;
};

export const MoveRankContextualCommentRequestSchema = z.object({
  direction: z.enum(['up', 'down']),
});
export type MoveRankContextualCommentRequest = z.infer<
  typeof MoveRankContextualCommentRequestSchema
>;

/*
 * Comment typeguards
 */

export function isApplicationComment(comment: ContextualComment): comment is ApplicationComment {
  return isApplicationCommentContext(comment.context);
}

export function isPositionComment(comment: ContextualComment): comment is PositionComment {
  return isPositionCommentContext(comment.context);
}

export function isCandidacyComment(comment: ContextualComment): comment is CandidacyComment {
  return isCandidacyCommentContext(comment.context);
}
export function isProjectComment(comment: ContextualComment): comment is ProjectComment {
  return isProjectCommentContext(comment.context);
}

export function isApplicationCommentContext(
  context: CommentContext,
): context is ApplicationCommentContext {
  return context.context === 'Application';
}

export function isPositionCommentContext(
  context: CommentContext,
): context is PositionCommentContext {
  return context.context === 'Position';
}

export function isCandidacyCommentContext(
  context: CommentContext,
): context is CandidacyCommentContext {
  return context.context === 'Candidacy';
}

export function isProjectCommentContext(context: CommentContext): context is ProjectCommentContext {
  return context.context === 'Project';
}

export function isPersonCommentContext(context: CommentContext): context is PersonCommentContext {
  return context.context === 'Person';
}
