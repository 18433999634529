import { NotificationProvider } from './context';
import NotificationBell from './NotificationBell';
import './index.less';

const Notifications = () => {
  return (
    <NotificationProvider>
      <NotificationBell />
    </NotificationProvider>
  );
};

export default Notifications;
