import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import config from '@src/config';
import qs from 'qs';

import { authService } from '@ct-internal/frontend-utilities';
import { getSocketIoClient } from '@shared/socket-io';

// Local development setup
import https from 'https';

const { API_BASE, BRAINS_API_BASE, AUTH_API_BASE, NEST_API_BASE } = config;

const _getPath = async (path, opts) => {
  try {
    const parsedUrl = /(.*)(\?.*)/.exec(path);
    let urlPath = path;
    let urlQuery = '';
    if (parsedUrl) {
      [, urlPath, urlQuery] = parsedUrl;
    }

    const queryParams = urlQuery ? qs.parse(urlQuery, { ignoreQueryPrefix: true }) : {};

    const params = opts.qs || opts.params || {};

    // append params with socket_id
    const socket = await getSocketIoClient();
    if (socket && socket.id) {
      params.socket_id = socket.id;
    }

    const queryString = qs.stringify(
      {
        ...queryParams,
        ...params,
      },
      {
        addQueryPrefix: true,
        encode: opts.encode || false,
      },
    );

    return urlPath + queryString;
  } catch (err) {
    console.error('_getPath err', err);
    return path;
  }
};

const _makeRequest = async (path, options, baseUrl = API_BASE) => {
  const opts = await getPayload(options);

  const _path = await _getPath(path, opts);
  const requestUrl = options.isAuthV3
    ? `${AUTH_API_BASE}/api/v3${_path}`
    : options.isNestApi
    ? `${NEST_API_BASE}/api/v3${_path}`
    : `${baseUrl}/api/v2${_path}`;

  const response = await fetch(requestUrl, opts);
  return response;
};

const request = async (path, options = {}, baseUrl = 'hire-api') => {
  try {
    const response = await _makeRequest(
      path,
      options,
      baseUrl === 'brains-api' ? BRAINS_API_BASE : API_BASE,
    );
    const json = await processResponse(response, options);

    if (!response.ok) {
      throw createError(json, response.status);
    }

    return json;
  } catch (error) {
    handleRequestError(error);
  }
};

const processResponse = async (response, options) => {
  if (options.asRaw || response.status === 204) {
    return response;
  }
  return await jsonBody(response);
};

const handleRequestError = (error) => {
  if (error.message === 'No valid token available' || error.message === 'Authentication failed') {
    console.error('Authentication error:', error.message);
  }
  throw error;
};

async function getPayload(options) {
  const { body } = options;
  const isJson = Object.prototype.toString.call(body) === '[object Object]';

  const agent = new https.Agent({ rejectUnauthorized: config.ENV !== 'local' });

  let headers = isJson ? { 'Content-Type': 'application/json' } : {};

  headers = Object.assign(headers, options.headers, {
    Authorization: await getAuthorization(options),
  });

  const extras = isJson ? { body: JSON.stringify(body) } : {};
  const result = Object.assign({}, options, { headers }, extras, { agent });

  return result;
}

async function getAuthorization({ authorizationHeader } = {}) {
  if (authorizationHeader) {
    return authorizationHeader;
  }

  const token = await authService.getValidToken();
  if (!token) {
    throw new Error('No valid token available');
  }
  return `JWT ${token}`;
}

function createError(data, status) {
  if (_isString(data.message)) {
    return data.message;
  } else if (_isObject(data.error)) {
    return data.error.message;
  } else if (_isString(data.error)) {
    return data.error;
  } else {
    return 'Unexpected error';
  }
}

const jsonBody = async (response) => {
  try {
    return response.json();
  } catch (err) {
    console.warn('The server did not send a JSON response', err);
    return {};
  }
};

export { getAuthorization };
export default request;
