import { z } from 'zod';
import { RevenueRate, RevenueRateSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const RevenueRateListFiltersSchema = RevenueRateSchema.pick({
  dtfrom: true,
  dtto: true,
  position_id: true,
  project_id: true,
  revenueamount: true,
}).partial();
export type RevenueRateListFilters = z.infer<typeof RevenueRateListFiltersSchema>;

export const RevenueRateListQuerySchema = z.object({
  filters: RevenueRateListFiltersSchema.optional(),
});
export type RevenueRateListQuery = z.infer<typeof RevenueRateListQuerySchema>;

export type RevenueRateListResponse = RevenueRate[];

export type RevenueRateListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/revenue-rate`,
  RevenueRateListResponse,
  undefined,
  RevenueRateListQuery,
  undefined
>;
