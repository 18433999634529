import React, { createContext, useContext, useEffect, useState } from 'react';

import intersection from 'lodash/intersection';

import { ActionRequest, ActionRequestComponentKeys } from '@ct-internal/api';

type HandleNotificationSelect = (action: 'check' | 'uncheck', notification: ActionRequest) => void;

interface NotificationContextProps {
  isBulkActions: boolean;
  setIsBulkActions: (value: boolean) => void;
  isActing: boolean;
  setIsActing: (value: boolean) => void;
  selectedNotifications: ActionRequest[];
  commonActions: ActionRequestComponentKeys[] | undefined;
  handleNotificationSelect: HandleNotificationSelect;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isBulkActions, setIsBulkActions] = useState(false);
  const [isActing, setIsActing] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<ActionRequest[]>([]);
  const [commonActions, setCommonActions] = useState<ActionRequestComponentKeys[] | undefined>();

  useEffect(() => {
    setSelectedNotifications([]);
    setCommonActions(undefined);
  }, [isBulkActions]);

  useEffect(() => {
    if (selectedNotifications.length > 0) {
      const common = intersection(
        ...selectedNotifications.map((notification) => notification.action_components),
      );

      const rescheduleIndex = common.indexOf(ActionRequestComponentKeys.RescheduleInterview);
      if (rescheduleIndex >= 0) {
        common.splice(rescheduleIndex, 1);
      }

      setCommonActions(common);
    } else {
      setCommonActions(undefined);
    }
  }, [selectedNotifications]);

  const handleNotificationSelect: HandleNotificationSelect = (action, notification) => {
    const newSelectedList = [...selectedNotifications];
    const index = newSelectedList.findIndex(
      (n) => n.action_request_id === notification.action_request_id,
    );

    switch (action) {
      case 'check':
        newSelectedList.push(notification);
        break;
      case 'uncheck':
        if (index >= 0) {
          newSelectedList.splice(index, 1);
        }
        break;
    }

    setSelectedNotifications(newSelectedList);
  };

  return (
    <NotificationContext.Provider
      value={{
        isBulkActions,
        setIsBulkActions,
        isActing,
        setIsActing,
        selectedNotifications,
        commonActions,
        handleNotificationSelect,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within an NotificationProvider');
  }
  return context;
};
