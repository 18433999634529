import { ActionRequests } from '../action-request/base';
import { z } from 'zod';

export const ActionRequestParamsSchema = z.object({
  slug: z.nativeEnum(ActionRequests),
  group: z.string(),
  action_components: z.string().array().nullable(),
  is_notification_only: z.boolean(),
});
export type ActionRequestParams = z.infer<typeof ActionRequestParamsSchema>;
