import moment from 'moment';
import type { Moment } from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { Form, Col, DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { FilterItemMinMax } from '../../types';
import { getFormattedDayjsTuple } from '@ct-internal/ui/src/utils/dateUtils';

export type RangePickerRanges = Record<string, [Dayjs, Dayjs]>;
interface RangeProps {
  format?: string;
  ranges?: RangePickerRanges;
}
const { RangePicker } = DatePicker;

interface FilterDateProps
  extends FilterItemMinMax,
    RangeProps,
    Omit<RangePickerProps, 'format' | 'ranges'> {}

const formatPeriod = (period: (Moment | null)[], format: string = 'YYYY-MM-DD') => {
  return period.map((date) => {
    if (!date || !moment(date).isValid()) {
      return null;
    }

    return moment(date, format);
  }) as [Moment | null, Moment | null];
};

const FilterDate = ({
  minName,
  maxName,
  label,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  format,
  ranges,
  ...dateRangeProps
}: FilterDateProps) => {
  const form = Form.useFormInstance();

  const handleChange = (dates: (Dayjs | null)[] | undefined | null) => {
    if (!dates || dates.some((date) => !date)) {
      form.setFieldValue(minName, undefined);
      form.setFieldValue(maxName, undefined);
      return;
    }

    const [startDate, endDate] = dates;
    form.setFieldValue(minName, dayjs(startDate).format(format));
    form.setFieldValue(maxName, dayjs(endDate).format(format));
  };

  const period = [form.getFieldValue(minName), form.getFieldValue(maxName)];

  const formattedPeriod = formatPeriod(period, format);

  return (
    <Col span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <Form.Item label={label}>
        <RangePicker
          showTime={false}
          style={{ width: '100%' }}
          value={getFormattedDayjsTuple(formattedPeriod)}
          onChange={handleChange}
          format={format}
          ranges={ranges}
          {...dateRangeProps}
        />
      </Form.Item>
    </Col>
  );
};

export default FilterDate;
