import { z } from 'zod';

export type PtoPsnYear = {
  id: number;
  person_id: string;
  year: number;
  used_alloc_amount: number;
  used_carryover_amount: number;
  vacation_alloc: number;
  seniority_extra_alloc: number;
  manual_adjustment_alloc: number;
  last_year_remaining: number;
  carryover_cap: number | null;
  created_at: Date;
  updated_at: Date;
};

export type PtoCarryoverRule = {
  id: number;
  contract_type: string;
  country_id: string;
  state: string;
  carryover_max_month: number | null;
  is_unlimited: boolean | null;
};

export const PtoSummarySchema = z.object({
  used: z.number(),
  earned: z.number(),
  remaining: z.number(),
  carryoverUsed: z.number(),
  carryoverRemaining: z.number(),
  totalUsed: z.number(),
  totalRemaining: z.number(),
  totalEarned: z.number(),
  proratedFactor: z.number(),
});

export type PtoSummary = z.infer<typeof PtoSummarySchema>;
