import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const PrjGetSowlistItemSchema = ProjectSchema.pick({
  project_id: true,
  sow_dt_signed: true,
  sow_link: true,
});
export type PrjGetSowlistItem = z.infer<typeof PrjGetSowlistItemSchema>;
export const PrjGetSowListParamsSchema = ProjectSchema.pick({
  project_id: true,
});
export type PrjGetSowListParams = z.infer<typeof PrjGetSowListParamsSchema>;

export type PrjGetSowListResponse = PrjGetSowlistItem[];

export type PrjGetSowlistEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-projects/${PrjGetSowListParams['project_id']}/sow-list`,
  PrjGetSowListResponse,
  PrjGetSowListParams,
  undefined,
  undefined
>;
