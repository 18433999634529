import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICEBulkSetNoShowRequestSchema = z.object({
  interview_calendar_events_id: z.coerce.number().array(),
});
export type ICEBulkSetNoShowRequest = z.infer<typeof ICEBulkSetNoShowRequestSchema>;

export type ICEBulkSetNoShowResponse = InterviewCalendarEvent[];

export type ICEBulkSetNoShowEndpoint = Endpoint<
  Method.POST,
  `/api/v2/interview-calendar-events/bulk-set-no-show`,
  ICEBulkSetNoShowResponse,
  undefined,
  undefined,
  ICEBulkSetNoShowRequest
>;
