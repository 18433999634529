import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const GetContractTemplateAdditionalParamsResponseSchema = z.object({
  additionalParams: z.string().array(),
});
export type GetContractTemplateAdditionalParamsResponse = z.infer<
  typeof GetContractTemplateAdditionalParamsResponseSchema
>;

export type GetContractTemplateAdditionalParamsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/contract-templates/:templateId/additional-params`,
  GetContractTemplateAdditionalParamsResponse,
  { templateId: number },
  {},
  {}
>;
