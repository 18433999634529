import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteUpdateInSFParamsSchema = z.object({
  comment_id: z.coerce.number(),
});
export type OpportunityNoteUpdateInSFParams = z.infer<typeof OpportunityNoteUpdateInSFParamsSchema>;

export type OpportunityNoteUpdateInSFEndpoint = Endpoint<
  Method.PUT,
  `/api/sf/opportunity-notes/in-salesforce/${OpportunityNoteUpdateInSFParams['comment_id']}`,
  {},
  OpportunityNoteUpdateInSFParams,
  undefined,
  undefined
>;
