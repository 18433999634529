import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const PsnRctrGoogleCalendarChannelSchema = z.object({
  person_id: z.string(),
  email: z.string(),
  calendar_id: z.string(),
  address: z.string(),
  is_active: z.boolean(),

  dt_expiration: dateString.nullable(),
  dt_last_notification: dateString.nullable(),

  resource_uuid: z.string().nullable(),
  resource_id: z.string().nullable(),
  resource_uri: z.string().nullable(),

  dt_created: dateString,
  dt_updated: dateString,
});

export type PsnRctrGoogleCalendarChannel = z.infer<typeof PsnRctrGoogleCalendarChannelSchema>;

export const PsnRctrGoogleCalendarStartWatchSchema = PsnRctrGoogleCalendarChannelSchema.pick({
  address: true,
  calendar_id: true,
});
export type PsnRctrGoogleCalendarStartWatch = z.infer<typeof PsnRctrGoogleCalendarStartWatchSchema>;

export const PsnRctrGoogleCalendarStopWatchSchema = PsnRctrGoogleCalendarChannelSchema.pick({
  resource_id: true,
});
export type PsnRctrGoogleCalendarStopWatch = z.infer<typeof PsnRctrGoogleCalendarStopWatchSchema>;

export const GCalWatcherSchema = z.object({
  kind: z.string(),
  resourceId: z.string(),
  resourceUri: z.string(),
  expiration: z.string(),
});
export type GCalWatcher = z.infer<typeof GCalWatcherSchema>;
