import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CommentSchema = z.object({
  comment_id: z.number(),
  comment: z.string(),
  author_id: z.string().nullable(),
  mongo_id: z.string().nullish(),
  tags: z.string().array().default([]).optional(),

  dt_created: dateString,
  dt_updated: dateString,
  dt_deleted: dateString.optional(),
});
export type Comment = z.infer<typeof CommentSchema>;

export const ERROR_AUTHOR_NOT_FOUND = 'Author not found.';
export const ERROR_COMMENT_NOT_FOUND = 'Comment not found.';

export type AuthorExpanded = {
  author_id: string | null;
  author_name: string | null;
  author_email: string | null;
  gravatar_url: string | null;
};

export const CommentAttributesSchema = z.object({
  comment_id: z.number(),
  is_important: z.boolean(),
  rank: z.number(),
});
export type CommentAttributes = z.infer<typeof CommentAttributesSchema>;

export const CommentWithAttributesSchema = CommentSchema.merge(CommentAttributesSchema);
export type CommentWithAttributes = z.infer<typeof CommentWithAttributesSchema>;
