import { createSelector } from 'reselect';

export const getlastSearchUrl = (state) => state.application.backUrl;
export const getApplications = (state) => state.entities.applications;
export const getApplicationStatuses = (state) => state.application.applicationStatuses;
export const getApplicationCalendly = (state) => state.application.applicationCalendly;
export const getRecruitersList = (state) => state.application.recruitersList;
export const getActiveRecruitersList = (state) => state.application.activeRecruitersList;
export const getApplicationPast = (state) => state.application.applicationPast;
export const getApplicationTests = (state) => state.application.applicationTests;

export const getApplicationInterviewOptions = (state) => state.application.interviewOptions;
export const getApplicationInterviewList = (state) => state.application.interviewList;
export const getApplicationTimelineList = (state) => state.application.timelineList;
export const getApplicationScorecardList = (state) => state.application.scorecardList;
export const getApplicationCurrentScorecard = (state) => state.application.scorecardList;
export const getInterviewDetails = (state) => state.application.interviewDetails;
export const getSelectedTestId = (state) => state.application.selectedTestId;
export const getApplicationTags = (state) => state.application.applicationTags;
export const getLoadingApplicationTags = (state) => state.application.loadingApplicationTags;

export const getCurrentApplicationId = (state, params) =>
  (params && params.applicationId && params.applicationId.trim()) || null;

export const currentApplicationSelector = createSelector(
  [getApplications, getCurrentApplicationId],
  (applications, applicationId) => applications[applicationId] || {},
);

export const getApplicationSkills = (state) => state.application.applicationSkills || [];
export const getApplicationAssignmentOptions = (state) => state.application.assignmentOptions;
export const getApplicationVideoAnswers = (state) => state.application.applicationVideoAnswers;
export const getApplicationSubmitError = (state) => state.application.submitApplicationError;

export const getIsLoading = (state) => state.application.isLoading;
export const getIsUploading = (state) => state.application.isUploading;
export const getIsOfferTermsLoading = (state) => state.application.isOfferTermsLoading;
export const getIsSendingTest = (state) => state.application.isSendingTest;
export const getReferredBy = (state) => state.application.referredBy;
export const getSourceList = (state) => state.application.sourceList;
export const getMediumList = (state) => state.application.mediumList;
export const getFoundList = (state) => state.application.foundList;
export const getAppliedList = (state) => state.application.appliedList;
export const getAdminList = (state) => state.application.adminList;
export const getSourcedByList = (state) => state.application.sourcedByList;
export const getCreateApplicantResult = (state) => state.application.createApplicantResult;
export const getInterviewerAvailability = (state) => state.application.interviewerAvailability;
export const getTechTestStatuses = (state) => state.application.techTestStatuses;
export const getBrainsAssignments = (state) => state.application.brainsAssignments;

export const getDepartmentOptions = (state) => state.application.departmentOptions;
