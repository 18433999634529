import compact from 'lodash/compact';

import {
  getFormattedCost,
  getFormattedSalaryWithContractType,
} from '@components/Person/PersonCostAndSalary';
import { ApplicationQuickSearchItem } from '@ct-internal/api';

export function oneLineSummary(application: ApplicationQuickSearchItem) {
  const salaryData = {
    cost_usd: (application.cost ?? application.salaryValue) * 1000,
    currency: application.salaryCurrency,
    currency_salary: application.salaryValue * 1000,
    usd_salary: application.usdSalary ?? application.salaryValue,
    contract_type: application.contractType,
  };
  const { formattedYearlySalary, contractTypeFormatted } = getFormattedSalaryWithContractType({
    data: salaryData,
  });

  return compact([
    `${getFormattedCost({
      data: salaryData,
    })} - ${formattedYearlySalary}${contractTypeFormatted} `,
    application.declaredYoE && `${application.declaredYoE}y`,
    application.timezoneShort,
    application.countryShort,
  ]).join(', ');
}
