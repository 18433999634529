import { z } from 'zod';
import { ContractTypeSchema } from '../schemas';
import { Endpoint, Method } from '../../../infrastructure';

export const ListContractTypesResponseSchema = z.object({
  contractTypes: ContractTypeSchema.array(),
});
export type ListContractTypesResponse = z.infer<typeof ListContractTypesResponseSchema>;

export const ListContractTypesQuerySchema = z.object({
  country: z.string().nullish(),
});
export type ListContractTypesQuery = z.infer<typeof ListContractTypesQuerySchema>;

export type ListContractTypesEndpoint = Endpoint<
  Method.GET,
  `/api/v2/contract-templates/contract-types`,
  ListContractTypesResponse,
  {},
  ListContractTypesQuery,
  {}
>;
