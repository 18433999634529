import { z } from 'zod';

export const ContractTypes = [
  'Individual',
  'Corporation',
  'W2',
  'T4',
  'Internship',
  'Other',
] as const;
export const ContractTypeSchema = z.enum(ContractTypes);
export type ContractType = z.infer<typeof ContractTypeSchema>;

export const ContractTemplateSchema = z.object({
  template_id: z.number(),
  title: z.string(),
  provider: z.string(),
  provider_id: z.string(),
  name: z.string(),
  type: z.enum(ContractTypes),
  paid_by: z.string(),
  country: z.string().nullish(),
  state_or_province: z.string().nullish(),
  business_name: z.string(),
  business_address: z.string(),
  salary_currency_options: z.string().array(),
});
export type ContractTemplate = z.infer<typeof ContractTemplateSchema>;
