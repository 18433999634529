interface IUser {
  _id: string;
  person: string;
}

const getState = () => {
  const state = localStorage.getItem('state');
  if (!state) {
    return {};
  }
  return JSON.parse(state);
};

export const getAccessToken = (): string => {
  const state = getState();
  return state.authentication?.user?.accessToken;
};

export const getUser = (): IUser => {
  const state = getState();
  return state.authentication?.user;
};
