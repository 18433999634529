import { z } from 'zod';

export const AnnouncementSchema = z.object({
  announcement_id: z.number(),
  app: z.string(),
  app_path: z.string().nullable(),
  message: z.string(),
  preview: z.string(),
  dt_start: z.date(),
  dt_end: z.date(),
});

export type Announcement = z.infer<typeof AnnouncementSchema>;
