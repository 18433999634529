import { z } from 'zod';
import { CommentListItemSchema } from '../../comment';
import { PersonCommentSchema } from '../base';

export const PersonCommentListPersonSchema = z.object({
  id: z.string(),
  name: z.string(),
  firstname: z.string(),
  surname: z.string(),
  email: z.string(),
  gravatar_url: z.string(),
});

export const PersonCommentListItemSchema = CommentListItemSchema.merge(PersonCommentSchema).extend({
  author: PersonCommentListPersonSchema,
  person: PersonCommentListPersonSchema,
});
export type PersonCommentListItem = z.infer<typeof PersonCommentListItemSchema>;
