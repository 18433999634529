import { z } from 'zod';
import { RevenueRate, RevenueRateSchema } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const RevenueRateGetParamsSchema = RevenueRateSchema.pick({
  revenuerate_id: true,
});
export type RevenueRateGetParams = z.infer<typeof RevenueRateGetParamsSchema>;

export type RevenueRateGetResponse = RevenueRate | null;

export type RevenueRateGetEndpoint = Endpoint<
  Method.GET,
  `/api/v2/revenue-rate/${RevenueRateGetParams['revenuerate_id']}`,
  RevenueRateGetResponse,
  RevenueRateGetParams,
  undefined,
  undefined
>;
