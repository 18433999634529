import { z } from 'zod';
import { OpportunityNoteSchema } from './base';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteCreateSchema = OpportunityNoteSchema.pick({
  opportunity_brains_id: true,
  text: true,
  user_brains_id: true,
});
export type OpportunityNoteCreate = z.infer<typeof OpportunityNoteCreateSchema>;

export const OpportunityNoteCreateRequestSchema = OpportunityNoteCreateSchema;
export type OpportunityNoteCreateRequest = z.infer<typeof OpportunityNoteCreateRequestSchema>;

export const OpportunityNoteCreateResponseSchema = OpportunityNoteSchema.pick({
  comment_brains_id: true,
});
export type OpportunityNoteCreateResponse = z.infer<typeof OpportunityNoteCreateResponseSchema>;

export type OpportunityNoteCreateEndpoint = Endpoint<
  Method.POST,
  '/api/sf/opportunity-notes',
  OpportunityNoteCreateResponse,
  undefined,
  undefined,
  OpportunityNoteCreateRequest
>;
