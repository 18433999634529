import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ActionComponent } from '.';
import { useBulkICESetConducted, useICESetConducted } from '@src/queries/InterviewCalendarEvent';

import { useSendGtmEvent } from '@shared/gtm';
import { useCloseNotification } from '../useCloseNotification';
import { useNotificationContext } from '../context';

export const InterviewConducted: ActionComponent = ({ notification, bulkNotifications }) => {
  const { setIsBulkActions, isActing, setIsActing } = useNotificationContext();

  const sendGtmEvent = useSendGtmEvent();
  const closeNotification = useCloseNotification({ notification, bulkNotifications });

  const onSuccess = async () => {
    await closeNotification();
    sendGtmEvent('notification_interview_conducted');
    setIsBulkActions(false);
    setIsActing(false);
  };

  const { mutateAsync: updateICE } = useICESetConducted({ onSuccess });
  const { mutateAsync: bulkConductedICE } = useBulkICESetConducted({ onSuccess });

  const markAsHappened = () => {
    setIsActing(true);
    if (notification) {
      updateICE({
        params: {
          interview_calendar_event_id: notification.metadata?.interview_calendar_event_id as number,
        },
      });
    }

    if (bulkNotifications) {
      bulkConductedICE({
        request: {
          interview_calendar_events_id: bulkNotifications.map(
            (notification) => notification.metadata?.interview_calendar_event_id as number,
          ),
        },
      });
    }
  };

  return (
    <Button
      disabled={isActing}
      type="link"
      size="small"
      icon={<CheckOutlined />}
      onClick={markAsHappened}
    >
      Mark as Conducted
    </Button>
  );
};

export default InterviewConducted;
