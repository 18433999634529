import { z } from 'zod';
import { PositionPostgresSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PositionSlimItemSchema = PositionPostgresSchema.pick({
  position_id: true,
  name: true,
  dtplannedend: true,
  dtplannedstart: true,
  hire_group: true,
}).extend({
  revenueamount: z.number().nullable(),
  has_assignment: z.boolean(),
  active_assignments: z
    .object({
      person_id: z.string(),
      name: z.string(),
    })
    .array()
    .nullable(),
});
export type PositionSlimItem = z.infer<typeof PositionSlimItemSchema>;

export const PositionSlimFiltersSchema = z.object({
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  islead_or_isstaffit: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
});
export type PositionSlimFilters = z.infer<typeof PositionSlimFiltersSchema>;

export const PositionSlimQuerySchema = z.object({
  filters: PositionSlimFiltersSchema.partial().optional(),
});
export type PositionSlimQuery = z.infer<typeof PositionSlimQuerySchema>;

export type PositionSlimResponse = PositionSlimItem[];

export type PositionSlimEndpoint = Endpoint<
  Method.GET,
  '/api/sql-positions/position-slim',
  PositionSlimResponse,
  undefined,
  PositionSlimQuery,
  undefined
>;
