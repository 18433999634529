import axios from 'axios';
import { config } from '../../config';
import { getAccessToken } from '../state';

export const brainsClient = axios.create({
  baseURL: config.API_BASE,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${getAccessToken()}`,
    },
  },
});
