import { z } from 'zod';

export const CriteriaAssessmentStatusSchema = z.enum([
  'Scheduled',
  'In Progress',
  'Completed',
  'Expired',
]);

export const CriteriaAssessmentRangeTypeSchema = z.enum(['RAW', 'PERCENTILE']);

export const CriteriaAssessmentRangeSchema = z.object({
  range_id: z.number(),
  category: z.string(),
  task: z.string(),
  score_type: z.string(),
  range_type: CriteriaAssessmentRangeTypeSchema,
  min_value: z.number(),
  max_value: z.number(),
  dt_created: z.number(),
  dt_updated: z.number().nullable(),
});

export const CriteriaAssessmentRangeWithJobSchema = CriteriaAssessmentRangeSchema.extend({
  job_id: z.string(),
});

export const CriteriaJobAssessmentSchema = z.object({
  job_assessment_id: z.number(),
  job_id: z.string(),
  package_id: z.string(),
  range_category: z.string(),
  slack_channels: z.string().nullable(),
  name: z.string(),
});

export const CriteriaAssessmentSlackChannelSchema = z.object({
  channel_id: z.number(),
  job_id: z.string(),
  slack_channel: z.string(),
});

export const CriteriaAssessmentSchema = z.object({
  assessment_id: z.number(),
  application_id: z.string(),
  order_id: z.string(),
  event_id: z.string().nullable(),
  status: CriteriaAssessmentStatusSchema,
  manually_created: z.boolean().default(false),

  ranking_score: z.number().nullable(),

  epp_percent_match: z.number().nullable(),
  epp_invalid: z.boolean().nullable(),
  epp_inconsistency: z.number().nullable(),
  epp_achievement: z.number().nullable(),
  epp_motivation: z.number().nullable(),
  epp_competitiveness: z.number().nullable(),
  epp_cooperativeness: z.number().nullable(),
  epp_managerial: z.number().nullable(),
  epp_assertiveness: z.number().nullable(),
  epp_extroversion: z.number().nullable(),
  epp_patience: z.number().nullable(),
  epp_self_confidence: z.number().nullable(),
  epp_conscientiousness: z.number().nullable(),
  epp_openness: z.number().nullable(),
  epp_stress_tolerance: z.number().nullable(),

  accounting: z.number().nullable(),
  admin_asst: z.number().nullable(),
  analyst: z.number().nullable(),
  bank_teller: z.number().nullable(),
  collections: z.number().nullable(),
  customer_service: z.number().nullable(),
  front_desk: z.number().nullable(),
  manager: z.number().nullable(),
  medical_asst: z.number().nullable(),
  production: z.number().nullable(),
  programmer: z.number().nullable(),
  sales: z.number().nullable(),

  ccat_raw_score: z.number().nullable(),
  ccat_percentile: z.number().nullable(),
  ccat_spatial_percentile: z.number().nullable(),
  ccat_verbal_percentile: z.number().nullable(),
  ccat_math_percentile: z.number().nullable(),
  ccat_invalid_score: z.number().nullable(),

  assessment_url: z.string().nullable(),
  report_url: z.string().nullable(),
  candidate_report_url: z.string().nullable(),

  expiry_date: z.number().nullable(),

  dt_created: z.number(),
  dt_updated: z.number().nullable(),
});

export const CriteriaAssessmentWithJobSchema = CriteriaAssessmentSchema.extend({
  name: z.string(),
  job_id: z.string(),
  job_name: z.string(),
  job_short_name: z.string(),
});

export const CriteriaAssessmentCreateSchema = CriteriaAssessmentSchema.pick({
  application_id: true,
  order_id: true,
  assessment_url: true,
  manually_created: true,
}).extend({
  expiry_date: z.string(),
});

export const CriteriaAssessmentUpdateSchema = CriteriaAssessmentSchema.omit({
  assessment_id: true,
  application_id: true,
  assessment_url: true,
  order_id: true,
  event_id: true,
  dt_created: true,
  dt_updated: true,
  expiry_date: true,
}).partial();

export type CriteriaAssessment = z.infer<typeof CriteriaAssessmentSchema>;

export type CriteriaAssessmentStatus = z.infer<typeof CriteriaAssessmentStatusSchema>;

export type CriteriaAssessmentRangeType = z.infer<typeof CriteriaAssessmentRangeTypeSchema>;

export type CriteriaAssessmentSlackChannel = z.infer<typeof CriteriaAssessmentSlackChannelSchema>;

export type CriteriaAssessmentWithJob = z.infer<typeof CriteriaAssessmentWithJobSchema>;

export type CriteriaAssessmentRange = z.infer<typeof CriteriaAssessmentRangeSchema>;

export type CriteriaAssessmentRangeWithJob = z.infer<typeof CriteriaAssessmentRangeWithJobSchema>;

export type CriteriaJobAssessment = z.infer<typeof CriteriaJobAssessmentSchema>;

export type CriteriaAssessmentCreate = z.infer<typeof CriteriaAssessmentCreateSchema>;

export type CriteriaAssessmentUpdate = z.infer<typeof CriteriaAssessmentUpdateSchema>;
