import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const InvoiceMstSchema = z.object({
  invoice_id: z.number(),
  invoice_num: z.number(),
  external_inv_num: z.string().nullable(),
  mongo_inv_id: z.string().nullable(),
  project_id: z.string(),
  ymonth: z.number(),
  seq_num: z.number(),
  dtinvoice: dateString,
  dtdue: dateString,
  dtservice_from: dateString,
  dtservice_to: dateString,
  client_notes: z.string().nullable(),
  internal_notes: z.string().nullable(),

  dtonhold: dateString.nullable(),
  dtreviewed_billing: dateString.nullable(),
  dt_reviewed_acct: dateString.nullable(),
  dtsent_ext: dateString.nullable(),

  is_locked: z.boolean(),
  is_adj_only: z.boolean(),
  is_empty: z.boolean().nullable(),

  dtcreate: dateString,
  dtupdate: dateString.nullable(),
});
export type InvoiceMst = z.infer<typeof InvoiceMstSchema>;
