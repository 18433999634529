import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteDeleteParamsSchema = z.object({
  comment_brains_id: z.coerce.number(),
});
export type OpportunityNoteDeleteParams = z.infer<typeof OpportunityNoteDeleteParamsSchema>;

export type OpportunityNoteDeleteEndpoint = Endpoint<
  Method.DELETE,
  `/api/sf/opportunity-notes/${OpportunityNoteDeleteParams['comment_brains_id']}`,
  {},
  OpportunityNoteDeleteParams,
  undefined,
  undefined
>;
