import { z } from 'zod';
import { ContractTypeSchema, ContractTemplateSchema } from '../schemas';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const ListContractTemplateResponseSchema = z.object({
  templates: ContractTemplateSchema.array(),
});
export type ListContractTemplateResponse = z.infer<typeof ListContractTemplateResponseSchema>;

export const ListContractTemplatesFiltersSchema = z
  .object({
    type: z.record(z.enum([FilterOperations.EQUAL]), ContractTypeSchema.nullable()),
    country: z.record(z.enum([FilterOperations.EQUAL]), z.string().nullable()),
    state_or_province: z.record(z.enum([FilterOperations.EQUAL]), z.string().nullable()),
  })
  .partial();
export type ListContractTemplatesFilters = z.infer<typeof ListContractTemplatesFiltersSchema>;

export const ListContractTemplateQuerySchema = z.object({
  filters: ListContractTemplatesFiltersSchema.optional(),
});
export type ListContractTemplateQuery = z.infer<typeof ListContractTemplateQuerySchema>;

export type ListContractTemplateEndpoint = Endpoint<
  Method.GET,
  `/api/v2/contract-templates`,
  ListContractTemplateResponse,
  {},
  ListContractTemplateQuery,
  {}
>;
