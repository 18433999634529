import { Button } from 'antd';
import { ActionComponent } from '.';
import { useBulkICESetDropped, useICESetDropped } from '@src/queries/InterviewCalendarEvent';
import { UserDeleteOutlined } from '@ant-design/icons';

import { useSendGtmEvent } from '@shared/gtm';
import { useCloseNotification } from '../useCloseNotification';
import { useNotificationContext } from '../context';

export const DropInterview: ActionComponent = ({ notification, bulkNotifications }) => {
  const { setIsBulkActions, isActing, setIsActing } = useNotificationContext();

  const sendGtmEvent = useSendGtmEvent();
  const closeNotification = useCloseNotification({ notification, bulkNotifications });

  const onSuccess = async () => {
    await closeNotification();
    sendGtmEvent('notification_interview_dropped');
    setIsBulkActions(false);
    setIsActing(false);
  };

  const { mutateAsync: updateICE } = useICESetDropped({ onSuccess });
  const { mutateAsync: bulkDroppedICE } = useBulkICESetDropped({ onSuccess });

  const markAsDrop = () => {
    setIsActing(true);
    if (notification) {
      updateICE({
        params: {
          interview_calendar_event_id: notification.metadata?.interview_calendar_event_id as number,
        },
      });
    }

    if (bulkNotifications) {
      bulkDroppedICE({
        request: {
          interview_calendar_events_id: bulkNotifications.map(
            (notification) => notification.metadata?.interview_calendar_event_id as number,
          ),
        },
      });
    }
  };

  return (
    <Button
      disabled={isActing}
      type="link"
      size="small"
      icon={<UserDeleteOutlined />}
      onClick={markAsDrop}
    >
      Drop interview
    </Button>
  );
};

export default DropInterview;
