import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICEBulkSetConductedRequestSchema = z.object({
  interview_calendar_events_id: z.coerce.number().array(),
});
export type ICEBulkSetConductedRequest = z.infer<typeof ICEBulkSetConductedRequestSchema>;

export type ICEBulkSetConductedResponse = InterviewCalendarEvent[];

export type ICEBulkSetConductedEndpoint = Endpoint<
  Method.POST,
  `/api/v2/interview-calendar-events/bulk-set-conducted`,
  ICEBulkSetConductedResponse,
  undefined,
  undefined,
  ICEBulkSetConductedRequest
>;
