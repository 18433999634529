import { z } from 'zod';
import { RevenueRateUpdateSchema } from '../crud';
import { RevenueRate } from '../base';
import { Endpoint, Method } from '../../../infrastructure';

export const RevenueRateUpdateRequestSchema = RevenueRateUpdateSchema.pick({
  dtfrom: true,
  dtto: true,
  revenueamount: true,
});
export type RevenueRateUpdateRequest = z.infer<typeof RevenueRateUpdateRequestSchema>;

export const RevenueRateUpdateParamsSchema = RevenueRateUpdateSchema.pick({
  revenuerate_id: true,
});
export type RevenueRateUpdateParams = z.infer<typeof RevenueRateUpdateParamsSchema>;

export type RevenueRateUpdateResponse = RevenueRate;

export type RevenueRateUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/revenue-rate/${RevenueRateUpdateParams['revenuerate_id']}`,
  RevenueRateUpdateResponse,
  RevenueRateUpdateParams,
  undefined,
  RevenueRateUpdateRequest
>;
