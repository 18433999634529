import { Button } from 'antd';
import { ActionComponent } from '.';
import { CheckOutlined } from '@ant-design/icons';

import { useSendGtmEvent } from '@shared/gtm';
import { useCloseNotification } from '../useCloseNotification';
import { useNotificationContext } from '../context';

export const CloseNotification: ActionComponent = ({ notification, bulkNotifications }) => {
  const { setIsBulkActions, isActing, setIsActing } = useNotificationContext();

  const sendGtmEvent = useSendGtmEvent();
  const closeNotification = useCloseNotification({ notification, bulkNotifications });

  const markAsDone = async () => {
    setIsActing(true);
    await closeNotification();
    sendGtmEvent('notification_marked_as_done');
    setIsBulkActions(false);
    setIsActing(false);
  };

  return (
    <Button
      disabled={isActing}
      type="link"
      size="small"
      icon={<CheckOutlined />}
      onClick={markAsDone}
    >
      Mark as done
    </Button>
  );
};

export default CloseNotification;
