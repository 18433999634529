import axios from 'axios';
import { config } from '../config';
import { getAccessToken } from './state';

export const httpClient = () =>
  axios.create({
    baseURL: config.STRAPI_API_BASE,
    withCredentials: true,
    headers: {
      common: {
        'Content-Type': 'application/json;charset=utf-8',
        'Brains-Authorization': getAccessToken(),
      },
    },
  });
