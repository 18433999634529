import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { CoachAiCheckinMetrics } from '../schemas';
import { CheckinReportFiltersSchema } from './checkin-report';

export const CheckinMetricsQuerySchema = z.object({
  filters: CheckinReportFiltersSchema.optional(),
});
export type CheckinMetricsQuery = z.infer<typeof CheckinMetricsQuerySchema>;

export type CheckinMetricsResponse = {
  metrics: CoachAiCheckinMetrics;
};

export type CheckinMetricsEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/checkin-metrics',
  CheckinMetricsResponse,
  {},
  CheckinMetricsQuery,
  {}
>;
