import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteDeleteInSFParamsSchema = z.object({
  comment_id: z.coerce.number(),
});
export type OpportunityNoteDeleteInSFParams = z.infer<typeof OpportunityNoteDeleteInSFParamsSchema>;

export type OpportunityNoteDeleteInSFEndpoint = Endpoint<
  Method.DELETE,
  `/api/sf/opportunity-notes/in-salesforce/${OpportunityNoteDeleteInSFParams['comment_id']}`,
  {},
  OpportunityNoteDeleteInSFParams,
  undefined,
  undefined
>;
