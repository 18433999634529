import { httpClient } from './httpClient';

type Object = { [key: string]: any };

export const strapiAPI = {
  get: async (path: string, query?: string) =>
    httpClient().get(`${path}${query ? `?${query}` : ''}`),

  post: (path: string, data: Object) => httpClient()({ url: path, data, method: 'POST' }),

  put: (path: string, data: Object) => httpClient()({ url: path, data, method: 'PUT' }),

  delete: (path: string) => httpClient()({ url: path, method: 'DELETE' }),
};
