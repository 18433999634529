import { z } from 'zod';
import { OpportunityNoteSchema } from './base';
import { Endpoint, Method } from '../../../infrastructure';

export const OpportunityNoteUpdateSchema = OpportunityNoteSchema.pick({
  text: true,
});
export type OpportunityNoteUpdate = z.infer<typeof OpportunityNoteUpdateSchema>;

export const OpportunityNoteUpdateRequestSchema = OpportunityNoteUpdateSchema;
export type OpportunityNoteUpdateRequest = z.infer<typeof OpportunityNoteUpdateRequestSchema>;

export const OpportunityNoteUpdateParamsSchema = z.object({
  comment_brains_id: z.coerce.number(),
});
export type OpportunityNoteUpdateParams = z.infer<typeof OpportunityNoteUpdateParamsSchema>;

export type OpportunityNoteUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/sf/opportunity-notes/${OpportunityNoteUpdateParams['comment_brains_id']}`,
  {},
  OpportunityNoteUpdateParams,
  undefined,
  OpportunityNoteUpdateRequest
>;
