import { useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { ActionComponent } from '.';
import { useUpdateNotification } from '@src/queries/Notification';
import GoogleEventModal from '@src/components/Application/ApplicationDetails/GoogleEventModal';
import { useSendGtmEvent } from '@shared/gtm';

export const RescheduleInterview: ActionComponent = ({ notification }) => {
  const sendGtmEvent = useSendGtmEvent();
  const [openGoogleReschedule, setOpenGoogleReschedule] = useState(false);

  const { mutateAsync: updateNotification } = useUpdateNotification();

  // const items: MenuProps['items'] = [
  //   {
  //     key: 'google-reschedule',
  //     label: (
  //       <Flex justify="space-between" align="center">
  //         <Typography.Text>Google Calendar Reschedule</Typography.Text>
  //         <GoogleOutlined style={{ marginLeft: 8 }} />
  //       </Flex>
  //     ),
  //   },
  //   {
  //     key: 'calendly-reschedule',
  //     label: (
  //       <Flex justify="space-between" align="center">
  //         <Typography.Text>Calendly Reschedule</Typography.Text>
  //         <CalendarOutlined style={{ marginLeft: 8 }} />
  //       </Flex>
  //     ),
  //   },
  // ];

  // const handleSelect: MenuProps['onClick'] = ({ key }) => {
  //   if (key === 'google-reschedule') {
  //     setOpenGoogleReschedule(true);
  //   }
  // };

  const markNotificationAsDone = () => {
    if (notification) {
      updateNotification({
        params: { action_request_id: notification.action_request_id },
        request: { dt_action_completed: moment().toISOString() },
      });
    }

    sendGtmEvent('notification_interview_rescheduled');
  };

  return (
    <>
      {/* <Dropdown menu={{ items, onClick: handleSelect }} trigger={['click']}> */}
      <Button
        type="link"
        size="small"
        icon={<CalendarOutlined />}
        onClick={() => setOpenGoogleReschedule(true)}
      >
        Reschedule Meeting
      </Button>
      {/* </Dropdown> */}

      {openGoogleReschedule && (
        <GoogleEventModal
          isReschedule
          isOpen={openGoogleReschedule}
          onClose={() => setOpenGoogleReschedule(false)}
          application_id={notification?.metadata?.application_id as string}
          onSuccess={markNotificationAsDone}
        />
      )}
    </>
  );
};

export default RescheduleInterview;
