import { z } from 'zod';
import { dateString, stringToBoolean } from '../../infrastructure/schema';
import { Endpoint, FilterOperations, Method, makeSorter } from '../../infrastructure';
import { ConfidenceLevel } from './base';

//Base
export const LeadListSchema = z.object({
  lead_id: z.string(),
  lead_name: z.string(),
  islead: z.boolean(),
  client_id: z.string(),
  client_name: z.string(),
  plannedstart: dateString,
  plannedend: dateString,
  hard_date: z.boolean(),
  isstaffit: z.boolean(),
  team_size: z.number(),
  target_Cost: z.number(),
  expected_revenue: z.number(),
  expected_cost: z.number(),
  timezone: z.string(),
  expected_duration: z.number(),
  expected_margin: z.number(),
  hire_group: z.array(z.string()),
  skills: z.string(),
  skills_array: z.array(z.string()),
  default_duration: z.number(),
  default_expected_team_size: z.number(),
  default_expected_monthly_revenue: z.number(),
  default_expected_monthly_cost: z.number(),
  sf_expected_monthly_revenue: z.number(),
  dt_archived: dateString.nullable(),
  is_plan_included: z.boolean(),
  is_pre_opportunity: z.boolean(),
  owner_id: z.string(),
  owner_name: z.string(),
  project_sf_id: z.string(),
  sf_stage: z.string().nullable(),
  probability: z.number().nullable(),
  duration_weeks: z.number().nullable(),
  is_sf_locked: z.boolean().nullable(),
  is_starter_project: z.boolean().nullable(),
  confidence_level: z.nativeEnum(ConfidenceLevel).nullable(),
});

export type LeadList = z.infer<typeof LeadListSchema>;

export const LeadListSorterSchema = makeSorter(
  z.enum([
    'plannedstart',
    'client_name',
    'hire_group',
    'confidence_level',
    'is_starter_project',
    'owner_name',
  ]),
);
export type LeadListSorter = z.infer<typeof LeadListSorterSchema>;

//Filters
export const LeadListFiltersSchema = z.object({
  lead_name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  hire_group: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  confidence_level: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  sales_rep: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  skills: z.record(z.enum([FilterOperations.IN]), z.string().array()).optional(),
  startDate: z
    .record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      z.string(),
    )
    .optional(),
  include_archived: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  only_sf: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  only_starter_projects: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
  is_pre_opportunity: z.record(z.enum([FilterOperations.EQUAL]), stringToBoolean).optional(),
});
export type LeadListFilters = z.infer<typeof LeadListFiltersSchema>;

//Query
export const LeadListQuerySchema = z.object({
  filters: LeadListFiltersSchema.optional(),
  sorter: LeadListSorterSchema.optional(),
});
export type LeadListQuery = z.infer<typeof LeadListQuerySchema>;

//Response
export type LeadListResponse = LeadList[];

//Endpoint
export type LeadListEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/leads',
  LeadListResponse,
  undefined,
  LeadListQuery,
  undefined
>;
