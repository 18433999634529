import { z } from 'zod';
import { dateString } from '../../../infrastructure';
import { CommentSchema } from '../../../modules';

export const OpportunityNoteSchema = z.object({
  comment_brains_id: z.number(),
  opportunity_brains_id: z.string(),
  user_brains_id: z.string(),
  text: z.string(),
  timestamp: dateString,
});

export type OpportunityNote = z.infer<typeof OpportunityNoteSchema>;

export const OpportunityNoteCreateInSFSchema = CommentSchema.pick({ comment_id: true });
export type OpportunityNoteCreateInSF = z.infer<typeof OpportunityNoteCreateInSFSchema>;

export const OpportunityNoteUpdateInSFSchema = CommentSchema.pick({
  comment_id: true,
});
export type OpportunityNoteUpdateInSF = z.infer<typeof OpportunityNoteUpdateInSFSchema>;

export const OpportunityNoteDeleteInSFSchema = CommentSchema.pick({
  comment_id: true,
});
export type OpportunityNoteDeleteInSF = z.infer<typeof OpportunityNoteDeleteInSFSchema>;
