import { ApplicationQuickSearchEndpoint } from '@ct-internal/api';
import APIRequest from '@src/shared/request';

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { APPLICATION_QUICK_SEARCH_KEY } from './keys';

export const useApplicationQuickSearch = ({
  query,
  options,
}: {
  query: ApplicationQuickSearchEndpoint['query'];
  options?: UseQueryOptions<any>;
}): UseQueryResult<ApplicationQuickSearchEndpoint['response']> => {
  return useQuery({
    queryKey: [APPLICATION_QUICK_SEARCH_KEY, query],
    queryFn: () =>
      APIRequest(`/application-search/quick`, {
        method: 'GET',
        qs: query,
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      }),
    ...options,
  });
};
