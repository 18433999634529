import { z } from 'zod';
import { CommentSchema } from '../base';

export const CommentListItemSchema = CommentSchema.extend({
  author_name: z.string(),
  gravatar_url: z.string(),
  author_email: z.string(),
  comment_tags: z.string().array().nullable(),
  dt_deleted: z.string().optional(),
});
export type CommentListItem = z.infer<typeof CommentListItemSchema>;
