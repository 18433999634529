import { useEffect, useState } from 'react';
import { Space, Select, Flex, Badge, Drawer, Typography, Button, Tooltip } from 'antd';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';

import { useNotifications } from '@src/queries/Notification';

import map from 'lodash/map';
import get from 'lodash/get';

import NotificationsList from './NotificationsList';
import { useApiQuery, useFilters } from '@ct-internal/reports';
import { ActionRequestsLabel, ListMyActionRequestsFiltersSchema } from '@ct-internal/api';

import { useSendGtmEvent } from '@shared/gtm';
import BulkActions from './BulkActions';
import { useNotificationContext } from './context';

const NotificationBell = () => {
  const { isBulkActions, setIsBulkActions, isActing, commonActions, selectedNotifications } =
    useNotificationContext();

  const sendGtmEvent = useSendGtmEvent();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filterSetup = useFilters(ListMyActionRequestsFiltersSchema);

  const query = useApiQuery(filterSetup.filters);
  const { data: notifications, refetch } = useNotifications({ query: query });

  const showDrawer = () => {
    setIsDrawerOpen(true);

    sendGtmEvent('notification_bell_clicked');
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  return (
    <>
      <Flex className="notification-bell" onClick={showDrawer}>
        <Badge count={notifications?.count}>
          <BellOutlined />
        </Badge>
      </Flex>
      <Drawer
        className="notification-drawer"
        title={
          <>
            <Flex align="center" justify="space-between">
              <Typography.Text>Action Requests</Typography.Text>
              <Badge count={notifications?.count} />
            </Flex>
          </>
        }
        footer={
          isBulkActions ? (
            <BulkActions actions={commonActions} selectedNotifications={selectedNotifications} />
          ) : undefined
        }
        placement="right"
        onClose={onClose}
        open={isDrawerOpen}
        forceRender={true}
        width={500}
      >
        <Flex justify="space-between" align="end" style={{ marginBottom: '10px' }}>
          <Space>
            <Typography.Text>Filter by type:</Typography.Text>
            <Select
              allowClear
              options={map(Object.keys(ActionRequestsLabel), (key) => ({
                label: get(ActionRequestsLabel, key),
                value: key,
              }))}
              onChange={(value) =>
                filterSetup.form.setFieldValue(filterSetup.fields.slug.EQUAL, value)
              }
              style={{ minWidth: '200px' }}
            />
          </Space>
          <Tooltip title={`${isBulkActions ? 'Cancel' : 'Active'} bulk actions`}>
            <Button disabled={isActing} onClick={() => setIsBulkActions(!isBulkActions)}>
              {isBulkActions ? <CloseOutlined /> : null}
              Bulk Actions
            </Button>
          </Tooltip>
        </Flex>
        <NotificationsList showDrawer={showDrawer} />
      </Drawer>
    </>
  );
};

export default NotificationBell;
