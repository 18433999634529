import { z } from 'zod';
import {
  Endpoint,
  FilterOperations,
  Method,
  ReportResponse,
  ReportsQuerySchema,
  dateString,
  makeSorter,
} from '../../infrastructure';

export const TORActivityReportItemSchema = z.object({
  person_id: z.string(),
  name: z.string(),
  coach_id: z.string(),
  coach_name: z.string(),
  dthired: dateString,
  gravatar_url: z.string(),
  dtterminated: dateString,
  department_name: z.string(),
  paid_working_days: z.number(),
  unpaid_working_days: z.number(),
  working_days: z.number(),
  last_year_days: z.number(),
  contract_type: z.string(),
  age: z.string(),
});
export const TOR_ACTIVITY_DEFAULT_PAGE_SIZE = 50;
export type TORActivityReportItem = z.infer<typeof TORActivityReportItemSchema>;

export const TORActivityReportFiltersSchema = z.object({
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
  dt_start: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString).optional(),
  dt_end: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), dateString).optional(),
  contract_type: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  working_days: z
    .record(
      z.enum([
        FilterOperations.EQUAL,
        FilterOperations.LESS_THAN_OR_EQUAL,
        FilterOperations.GREATER_THAN_OR_EQUAL,
      ]),
      z.coerce.number(),
    )
    .optional(),
  paid_days: z
    .record(
      z.enum([FilterOperations.LESS_THAN_OR_EQUAL, FilterOperations.GREATER_THAN_OR_EQUAL]),
      z.coerce.number(),
    )
    .optional(),
  unpaid_days: z
    .record(
      z.enum([FilterOperations.LESS_THAN_OR_EQUAL, FilterOperations.GREATER_THAN_OR_EQUAL]),
      z.coerce.number(),
    )
    .optional(),
});
export type TORActivityReportFilters = z.infer<typeof TORActivityReportFiltersSchema>;

export const TORActivityReportSorterSchema = makeSorter(
  z.enum(['name', 'paid_working_days', 'unpaid_working_days', 'last_year_days', 'working_days']),
);
export type TORActivityReportSorter = z.infer<typeof TORActivityReportSorterSchema>;

export const TORActivityReportQuerySchema = ReportsQuerySchema.extend({
  filters: TORActivityReportFiltersSchema.optional(),
  sorter: TORActivityReportSorterSchema.optional(),
});
export type TORActivityReportQuery = z.infer<typeof TORActivityReportQuerySchema>;

export type TORActivityReportResponse = ReportResponse<TORActivityReportItem>;

export type TORActivityReportEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sq-reports/tor-activity-report`,
  TORActivityReportResponse,
  undefined,
  TORActivityReportQuery,
  undefined
>;
